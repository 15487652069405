import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { Card, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Tooltip as ReactTooltip } from "react-tooltip";

import yesicon from "../../assets/images/safetyProtection/yes-icon.png";
import noicon from "../../assets/images/safetyProtection/no-icon.png";
import naicon from "../../assets/images/safetyProtection/no-image.png";
import tripIcon from "../../assets/images/TripreportIcon.png";
import distanceIcon from "../../assets/images/distanceNewIcon.png";

import logo from "../../assets/images/logo-sm.svg";
// import CarouselPage from './CarouselPage';
import "bootstrap/dist/css/bootstrap.min.css";
import motivLogo from "../../assets/images/ruduicon.png";
import { CSVLink } from "react-csv";
import { CopyToClipboard } from "react-copy-to-clipboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link, Navigate, useNavigate } from "react-router-dom";
import MyRidesController from "../../controller/Rides/MyRides.js";
import ValueFormat from "../../util/ValueFormat.js";
import {
  RIDEDETAILS,
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_ID,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { useDispatch, useSelector } from "react-redux";
import { myUserSearch } from "../../store/actions";
import Loader from "../../components/Common/loader";
import MyRidedataList from "./myridetabledata";
import { PiDownloadSimple } from "react-icons/pi";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import { displayText } from "../../constant/MessageConstant";
import ToastService from "../../components/Common/toastService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bluetooth from "../../assets/images/Bluetooth.png";
import greenCar from "../../assets/images/car_icon.png";
import redCar from "../../assets/images/redCar.png";
import sandClock from "../../assets/images/sand_clock.png";
import startIcon from "../../assets/images/start_icon.png";
import calendar from "../../assets/images/calendar_icon.png";
import gpsRoute from "../../assets/images/gps_route_icon.png";
import destinationIcon from "../../assets/images/destination_icon.png";
import startOverlay from "../../assets/images/start_overlay.png";
import dotted from "../../assets/images/dotted_vertical_line.png";
import back from "../../assets/images/back.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import baterryStart from "../../assets/images/batteryHigh.svg";
import baterryEnd from "../../assets/images/baterryLow.svg";
import Converter from "../../components/Common/EncodeDecode.js";
import i1 from "../../assets/images/adminDashboard/ad-dr-name.svg";
import i2 from "../../assets/images/adminDashboard/ad-dr-uname.svg";
// import i3 from "../../assets/images/adminDashboard/ad-dr-rank.svg";
import BatteryCosumIcon from "../../assets/images/adminDashboard/myRidesPageIcons/BatteryConsumptionIcon.svg";
import i4 from "../../assets/images/adminDashboard/totalRides.svg";
import i5 from "../../assets/images/adminDashboard/ad-dr-riskAlerts.svg";
import i6 from "../../assets/images/adminDashboard/ad-dr-otherAlerts.svg";
import category_image from "../../assets/images/adminDashboard/category.svg";
import ride_name from "../../assets/images/adminDashboard/ride_name.svg";
import i7 from "../../assets/images/adminDashboard/ad-dr-overspeed.svg";
// import i8 from "../../assets/images/adminDashboard/ad-dr-mobileCall.svg";
import CallCountIcon from "../../assets/images/adminDashboard/myRidesPageIcons/CallCountIcon.svg";
// import i9 from "../../assets/images/adminDashboard/ad-dr-mobileScreen.svg";
import ScreenCountIcon from "../../assets/images/adminDashboard/myRidesPageIcons/ScreenCountIcon.svg";
// import i10 from "../../assets/images/adminDashboard/ad-dr-acceleration.svg";
import AccelerationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AccelerationIcon.svg";
// import i11 from "../../assets/images/adminDashboard/ad-dr-Braking.svg";
import BreakingIcon from "../../assets/images/adminDashboard/myRidesPageIcons/BrakingIcon.svg";
// import i12 from "../../assets/images/adminDashboard/ad-dr-cornering.svg";
import CorneringIcon from "../../assets/images/adminDashboard/myRidesPageIcons/CorneringIcon.svg";
import i13 from "../../assets/images/adminDashboard/ad-dr-score.svg";
import crash from "../../assets/images/adminDashboard/crash_accident.svg";
// import DetailViewd from "../../assets/images/rideDetails/DetailedView.svg";
import DetailViewd from "../../assets/images/adminDashboard/myRidesPageIcons/journeyViewIconNew.svg";
import UBIDrivingScore from "../../assets/images/Ubi Images/UBIDrivingScore.svg";
// import UBIAnticipation from "../../assets/images/Ubi Images/UBIAnticipation.svg";
import UBIAnticipation from "../../assets/images/adminDashboard/myRidesPageIcons/AnticipationIcon.svg";
// import UBISelfConfidence from "../../assets/images/Ubi Images/UBISelfConfidence.svg";
import UBISelfConfidence from "../../assets/images/adminDashboard/myRidesPageIcons/SelfConfidenceIcon.svg";
// import UBIDrivingSkill from "../../assets/images/Ubi Images/UBIDrivingSkill.svg";
import UBIDrivingSkill from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingSkillIcon.svg";
// import UBIDrivingStyle from "../../assets/images/Ubi Images/UBIDrivingStyle.svg";
import UBIDrivingStyle from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingStyleIcon.svg";
// import UBIDrivingState from "../../assets/images/Ubi Images/UBIDrivingState.svg";
import UBIDrivingState from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingStateIcon.svg";
import UBIMobileScreenUse from "../../assets/images/Ubi Images/UBIMobileScreenUse.svg";
// import UBIMobileCallUse from "../../assets/images/Ubi Images/UBIMobileCallUse.svg";
import UBIMobileCallUse from "../../assets/images/adminDashboard/myRidesPageIcons/MobileUsageIcon.svg";
// import UBIKmsDriven from "../../assets/images/Ubi Images/UBIKmsDriven.svg";
import OverSpeedIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIconNew.svg";
// import UBIStressStrain from "../../assets/images/Ubi Images/UBIStressStrain.svg";
import UBIStressStrain from "../../assets/images/adminDashboard/myRidesPageIcons/AccBrakingCorIcon.svg";
import UBIInfoIcon from "../../assets/images/adminDashboard/myRidesPageIcons/infoIcon.svg";
import UBIScoreImage from "../../assets/images/Ubi Images/newDrivingEfficiencyImage.svg";
import tdistance from "../../assets/images/adminDashboard/ad-2r-distance.svg";
import tduration from "../../assets/images/adminDashboard/ad-2r-time.svg";
// import battery from "../../assets/images/adminDashboard/battery_new.svg";
import battery from "../../assets/images/adminDashboard/myRidesPageIcons/chargerIcon.svg";
// import clock from "../../assets/images/adminDashboard/clock_new.svg";
import clock from "../../assets/images/adminDashboard/myRidesPageIcons/clockIcon.svg";
import CallCountIconNewOne from "../../assets/images/adminDashboard/myRidesPageIcons/CallCountIconNew.svg";
import CountImage from "../../assets/images/adminDashboard/myRidesPageIcons/CountImage.svg";

import steeringWheel from "../../assets/images/adminDashboard/steering-wheel.svg";
import locationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIcon.svg";
import calendarIcon from "../../assets/images/adminDashboard/calendarIcon.svg";
import minsIcon from "../../assets/images/adminDashboard/myRidesPageIcons/myRidesMins.svg";
import "../../pages/MyRides/MyRidesMobScreen.css";

const TableStyle = {
  table: {
    width: "100%",
    display: "table",
    borderSpacing: 0,
    border: "0.2px",
    margin: "0px",
  },
  th: {
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: "#fff",
    height: "0px",
    fontWeight: 600,
    fontSize: "12px",
  },
};

function NewRideList() {
  const history = useNavigate();
  const userType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  const [workFilter, setWorkFilter] = useState("");
  const [categoryValue, setCategory] = useState("");
  const [loginType, setLoginType] = useState("");
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [excelData, setExcelData] = useState([]);
  const [ddData, setDDData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextShow, setNextShow] = useState();
  const [prevShow, setPrevShow] = useState();
  const [totalPageCount, setTotalPageCount] = useState();
  const [apiData, setApiData] = useState([]);
  const [indexStart, setIndexStart] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [dataLength, setDataLength] = useState(null);
  const [userId, setUserId] = useState(localStorage.getItem(SC_USER_ID));
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [count, setCount] = useState(0);

  const pageSize = 5;
  const inputRef = useRef();
  const [modalIsOpen, setIsOpen] = useState(false);

  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };
  const [showLoader, setShowLoader] = useState(false);

 

  const newRideListGet = async (indexStartOfSet) => {
    try {
      setShowLoader(true);
      var result = await MyRidesController.newMyRidesList(
        indexStartOfSet,
        filter,
        workFilter,
        categoryValue,
        searchText,
        pageSize,
        startDateTime,
        endDateTime
      );
      setShowLoader(false);
      var resultJSON = JSON.parse(result);
      console.log("Ride List Data", result);
      var status = resultJSON.status;
      var updatedTableData = [];
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        setDataLength(resultJSONData.length);
        console.log("Ride List Data", resultJSONData);
        for (let i = 0; i < resultJSONData.length; i++) {
          var userRideDetail = resultJSONData[i];
          var rideName = ValueFormat.parseAsString(userRideDetail.name);
          var drivingBehaviourScore =
            ValueFormat.parseAsFloat(userRideDetail.drivingBehaviourScore, 0) ||
            0;
          var calculatedDrivingScore =
            ValueFormat.parseAsFloat(
              userRideDetail.calculatedDrivingScore,
              0
            ) || 0;
          var userId = ValueFormat.parseAsString(userRideDetail.userId);
          var overallDrivingScore =
            ValueFormat.parseAsFloat(userRideDetail.drivingScorePoint, 0) || 0;
          var overallAnticipation =
            ValueFormat.parseAsFloat(userRideDetail.anticipationPoint, 0) || 0;
          var overallSelfConfidence =
            ValueFormat.parseAsFloat(userRideDetail.selfConfidencePoint, 0) ||
            0;
          var overallDrivingSkill =
            ValueFormat.parseAsFloat(userRideDetail.drivingSkillPoint, 0) || 0;
          var dStyleValue =
            ValueFormat.parseAsFloat(userRideDetail.drivingStylePoint, 0) || 0;
          var dStateValue =
            ValueFormat.parseAsFloat(userRideDetail.drivingStatePoint, 0) || 0;
          var kmsDriven =
            ValueFormat.parseAsFloat(userRideDetail.kmsDrivenPoint, 0) || 0;
          var mobileCallValue =
            ValueFormat.parseAsFloat(userRideDetail.mobileCallPoint, 2) || 0;
          var mobileScreenValue =
            ValueFormat.parseAsFloat(userRideDetail.mobileScreenPoint, 2) || 0;
          var mobileUsage =
            parseFloat(userRideDetail.mobileCallPoint) +
            parseFloat(userRideDetail.mobileScreenPoint);
          var mobileUsageTotal = ValueFormat.parseAsFloat(mobileUsage, 0) || 0
          console.log(mobileCallValue, mobileScreenValue, "mobileScreenValue");
          var abcPoint =
            ValueFormat.parseAsFloat(userRideDetail.accBrakCorPoint, 0) || 0;
          var cpp =
            ValueFormat.parseAsFloat(userRideDetail.crashProbabilityScore, 0) ||
            0;
          var crashProbabilityScore =
            ValueFormat.parseAsFloat(userRideDetail.crashProbabilityScore, 0) ||
            0;
          console.log(cpp, userRideDetail.crashProbabilityScore, "cppppp");

          var totalEfficiencyRaw =
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.drivingScorePoint, 2)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.anticipationPoint, 2)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.selfConfidencePoint, 2)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.drivingSkillPoint, 2)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.drivingStylePoint, 2)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.drivingStatePoint, 2)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.kmsDrivenPoint, 2)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.mobileCallPoint, 2)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.mobileScreenPoint, 2)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.accBrakCorPoint, 2)
            );

          var totalEfficiency = ValueFormat.parseAsFloat(totalEfficiencyRaw, 0);
          var totalEfficiencyTV = ValueFormat.parseAsFloat(
            totalEfficiencyRaw,
            2
          );
          var totalEfficiencyTVNew = ValueFormat.parseAsFloat(
            userRideDetail.calculatedDrivingScore,
            2
          );

          var overallDrivingScoreTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.drivingScorePoint, 2) ||
            "-";
          var overallAnticipationTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.anticipationPoint, 2) ||
            "-";
          var overallSelfConfidenceTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.selfConfidencePoint, 2) ||
            "-";
          var overallDrivingSkillTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.drivingSkillPoint, 2) ||
            "-";
          var dStyleValueTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.drivingStylePoint, 2) ||
            "-";
          var dStateValueTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.drivingStatePoint, 2) ||
            "-";
          var kmsDrivenTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.kmsDrivenPoint, 2) || "-";
          var mobileCallValueTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.mobileCallPoint, 2) || "-";
          var mobileScreenValueTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.mobileScreenPoint, 2) ||
            "-";
          var abcPointTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.accBrakCorPoint, 2) || "-";

          var efficiency =
            ValueFormat.parseAsFloat(userRideDetail.drivingEfficiency, 0) ||
            "-";
          var efficiencyTooltipValue =
            ValueFormat.parseAsFloat(userRideDetail.drivingEfficiency, 2) ||
            "-";

          var totalSevereAccelerationLowCount =
            ValueFormat.parseAsFloat(
              userRideDetail.severeAccelerationLowCount,
              2
            ) || "-";
          var totalSevereAccelerationMediumCount =
            ValueFormat.parseAsFloat(
              userRideDetail.severeAccelerationMediumCount,
              2
            ) || "-";
          var totalSevereAccelerationHighCount =
            ValueFormat.parseAsFloat(
              userRideDetail.severeAccelerationHighCount,
              2
            ) || "-";

          var totalAccelerationCount =
            parseFloat(
              ValueFormat.parseAsFloat(
                userRideDetail.severeAccelerationLowCount,
                0
              )
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(
                userRideDetail.severeAccelerationMediumCount,
                0
              )
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(
                userRideDetail.severeAccelerationHighCount,
                0
              )
            );

          var totalSevereBrakingLowCount =
            ValueFormat.parseAsFloat(userRideDetail.severeBrakingLowCount, 2) ||
            "-";
          var totalSevereBrakingMediumCount =
            ValueFormat.parseAsFloat(
              userRideDetail.severeBrakingMediumCount,
              2
            ) || "-";
          var totalSevereBrakingHighCount =
            ValueFormat.parseAsFloat(
              userRideDetail.severeBrakingHighCount,
              2
            ) || "-";

          var totalBrakingCount =
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.severeBrakingLowCount, 0)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(
                userRideDetail.severeBrakingMediumCount,
                0
              )
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(userRideDetail.severeBrakingHighCount, 0)
            );

          var totalSevereCorneringLowCount =
            ValueFormat.parseAsFloat(
              userRideDetail.severeCorneringLowCount,
              2
            ) || "-";
          var totalSevereCorneringMediumCount =
            ValueFormat.parseAsFloat(
              userRideDetail.severeCorneringMediumCount,
              2
            ) || "-";
          var totalSevereCorneringHighCount =
            ValueFormat.parseAsFloat(
              userRideDetail.severeCorneringHighCount,
              2
            ) || "-";

          var totalCorneringCount =
            parseFloat(
              ValueFormat.parseAsFloat(
                userRideDetail.severeCorneringLowCount,
                0
              )
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(
                userRideDetail.severeCorneringMediumCount,
                0
              )
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(
                userRideDetail.severeCorneringHighCount,
                0
              )
            );

          var totalMobileUseInAcceptedCount =
            ValueFormat.parseAsFloat(
              userRideDetail.mobileUseInAcceptedCount,
              0
            ) || "-";
          var totalMobileUseInAcceptedDistance =
            ValueFormat.parseAsFloat(
              userRideDetail.mobileUseInAcceptedDistance,
              0
            ) || "-";
          const parsedNum = parseFloat(
            userRideDetail.mobileUseInAcceptedDuration
          );
          var totalMobileUseInAcceptedDuration = Number.isInteger(parsedNum)
            ? parsedNum
            : ValueFormat.parseAsFloat(
                userRideDetail.mobileUseInAcceptedDuration,
                2
              ) || "-";
          var totalMobileUseInAcceptedDurationTV =
            ValueFormat.parseAsFloat(
              userRideDetail.mobileUseInAcceptedDuration,
              2
            ) || "-";

          var totalMobileScreenScreenOnCount =
            ValueFormat.parseAsFloat(
              userRideDetail.mobileScreenScreenOnCount,
              0
            ) || "-";
          var totalMobileScreenScreenOnDistance =
            ValueFormat.parseAsFloat(
              userRideDetail.mobileScreenScreenOnDistance,
              0
            ) || "-";
          const parsedNumMobileScreen = parseFloat(
            userRideDetail.mobileScreenScreenOnDuration
          );
          var totalMobileScreenScreenOnDuration = Number.isInteger(
            parsedNumMobileScreen
          )
            ? parsedNumMobileScreen
            : ValueFormat.parseAsFloat(
                userRideDetail.mobileScreenScreenOnDuration,
                2
              ) || "-";
          var totalMobileScreenScreenOnDurationTV =
            ValueFormat.parseAsFloat(
              userRideDetail.mobileScreenScreenOnDuration,
              2
            ) || "-";

          var totalOverSpeedCount =
            ValueFormat.parseAsFloat(userRideDetail.overSpeedCount, 0) || "-";
          const parsedNumOverSpeed = parseFloat(
            userRideDetail.overSpeedDuration
          );
          var totalOverSpeedDuration = Number.isInteger(parsedNumOverSpeed)
            ? parsedNumOverSpeed
            : ValueFormat.parseAsFloat(userRideDetail.overSpeedDuration, 2) ||
              "-";
          var totalOverSpeedDurationTV =
            ValueFormat.parseAsFloat(userRideDetail.overSpeedDuration, 2) ||
            "-";
          var totalMediumRiskCount =
            ValueFormat.parseAsFloat(userRideDetail.mediumRiskCount, 0) || "-";
          var totalHighRiskCount =
            ValueFormat.parseAsFloat(userRideDetail.highRiskCount, 0) || "-";

          var tKiloMeter =
            ValueFormat.parseAsFloat(userRideDetail.kiloMeter, 2) || "-";
          var tKiloMeterTV =
            ValueFormat.parseAsFloat(userRideDetail.kiloMeter, 2) || "-";
          var tTravelTime =
            ValueFormat.parseAsFloat(userRideDetail.travelTime, 2) || "-";
          var tTravelTimeTV =
            ValueFormat.parseAsFloat(userRideDetail.travelTime, 2) || "-";

          var crashCount =
            ValueFormat.parseAsString(userRideDetail.crashCount) || "-";
          var categoryObject = ValueFormat.parseAsObject(
            userRideDetail.rideBox
          );
          var categoryTemp = ValueFormat.parseAsInt(categoryObject.rideBox);
          var categoryTooltip =
            categoryTemp == 1
              ? "Safe Driving"
              : categoryTemp == 2
              ? "Sportive Driving"
              : categoryTemp == 3
              ? "Quite Safe<br/>Driving" // Adding a line break before "Driving"
              : categoryTemp == 4
              ? "Reckless Driving"
              : categoryTemp == 5
              ? "Quite Dangerous<br/>Driving" // Adding a line break before "Driving"
              : "Dangerous Driving";
          var category =
            categoryTemp == 1
              ? "C1"
              : categoryTemp == 2
              ? "C2"
              : categoryTemp == 3
              ? "C3"
              : categoryTemp == 4
              ? "C4"
              : categoryTemp == 5
              ? "C5"
              : "C6";

          var status = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.status)
          );
          var rideId = ValueFormat.parseAsString(userRideDetail.deviceId);
          var statusTemp = "Completed";
          if (searchText == "") {
            if (i === 0 && indexStartOfSet === 0 && status === "In progress") {
              statusTemp = "In progress";
            } else if (
              i === 0 &&
              indexStartOfSet === 0 &&
              status === "Registered"
            ) {
              statusTemp = "Registered";
            } else if (status === "Completed") {
              statusTemp = "Completed";
            } else {
              statusTemp = "Completed";
            }
          } else if (searchText != "") {
            if (status === "Completed") {
              statusTemp = "Completed";
            } else {
              statusTemp = "Completed";
            }
          }
          var divisionId = ValueFormat.parseAsString(userRideDetail.divisionId);

          var rideCategory = ValueFormat.parseAsString(userRideDetail.category);

          var startTime =
            ValueFormat.parseAsString(userRideDetail.startDateTimeToTimeZone) ||
            "-";
          var endTime =
            ValueFormat.parseAsString(userRideDetail.endDateTimeToTimeZone) ||
            "-";
          var batterylevelStart =
            ValueFormat.parseAsFloat(userRideDetail?.batteryLevelStart, 0) ||
            "-";
          var batterylevelEnd =
            ValueFormat.parseAsFloat(userRideDetail?.batteryLevelEnd, 0) || "-";
          var longEvent = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.subType)
          );
          var event = ValueFormat.parseAsString(userRideDetail.subType) || "";
          var shortEvent = "";
          if (event === "BACKGROUND_AUTO") {
            shortEvent = "BGA";
          } else if (event === "BACKGROUND_MANUAL") {
            shortEvent = "BGM";
          } else if (event === "FOREGROUND_AUTO") {
            shortEvent = "FGA";
          } else if (event === "FOREGROUND_MANUAL") {
            shortEvent = "FGM";
          } else {
            shortEvent = "-";
          }

          var totalCount = ValueFormat.parseAsInt(userRideDetail.totalCount);
          setTotalPageCount(Math.ceil(totalCount / pageSize));
          setNextShow(totalCount - indexStartOfSet > pageSize);

          let totalBehaviourScore = (ValueFormat.formatDecimalIfRounded(dStyleValue) + ValueFormat.formatDecimalIfRounded(dStateValue) + parseFloat(mobileUsageTotal) + ValueFormat.formatDecimalIfRounded(kmsDriven) + ValueFormat.formatDecimalIfRounded(abcPoint));
          
          var summaryRide = "";

          if(calculatedDrivingScore >= 0 && calculatedDrivingScore <= 50){
            summaryRide = "-"
          }else if(calculatedDrivingScore > 50 && calculatedDrivingScore <= 80){
             summaryRide = "-"
          }else if(calculatedDrivingScore > 80 && calculatedDrivingScore <= 93){
             summaryRide = "-"
          }else if(calculatedDrivingScore > 93 && calculatedDrivingScore <= 100){
             summaryRide = "-"
          }else{
             summaryRide = "-"
          }
         
          // if (calculatedDrivingScore > 90) {         
          //  summaryRide = "You drive safely. You have excellent driving practice and you have a nature of very cautious"
          // } else if (calculatedDrivingScore > 60 && calculatedDrivingScore <= 90) {          
          //  summaryRide = "You are surprised from time to time by the singularities of the road poorly anticipated. Yet you have good driving practice and a normal self-confidence. Learn how to focus your attention on key places that correspond to alerts and you will lower your risk."
          // } else {         
          //  summaryRide = "Your driving is risky. You have good driving practice and a normal self-confidence. You have probably taken some bad habits to urgently fix to lower your risk."
          // }
          var dayPercentage = ValueFormat.parseAsFloat(userRideDetail.dayPercentage, 0);
          var nightPercentage = 100 - dayPercentage;
          
          var urbanKilometer = ValueFormat.parseAsFloat(userRideDetail.urbanKilometer, 2);
          var ruralKilometer = ValueFormat.parseAsFloat(userRideDetail.ruralKilometer, 2);          
          var highwayKilometer = ValueFormat.parseAsFloat(userRideDetail.highwayKilometer, 2);
         


          var resultObject = {
            rideName: rideName,
            drivingBehaviourScore: totalBehaviourScore,
            calculatedDrivingScore: calculatedDrivingScore,
            totalEfficiencyTVNew: totalEfficiencyTVNew,
            category: category,
            categoryTooltip: categoryTooltip,
            drivingScore: overallDrivingScore,
            rideId: rideId,
            userId: userId,
            divisionId: divisionId,
            status: status,
            StatusTemp: statusTemp,
            ABCPoint: abcPoint,
            mobileScreenPointNew: mobileScreenValue,
            mobileUsePointNew: mobileCallValue,
            overallAnticipation: overallAnticipation,
            overallSelfConfidence: overallSelfConfidence,
            overallDrivingSkill: overallDrivingSkill,
            totalKmSPoint: kmsDriven,
            DSP: dStateValue,
            DSTP: dStyleValue,
            efficiency: efficiency,
            totalEfficiency: totalEfficiency,
            efficiencyTooltipValue: efficiencyTooltipValue,
            crashCount: crashCount,
            overallDrivingScoreTooltipValue: overallDrivingScoreTooltipValue,
            overallAnticipationTooltipValue: overallAnticipationTooltipValue,
            overallSelfConfidenceTooltipValue:
              overallSelfConfidenceTooltipValue,
            overallDrivingSkillTooltipValue: overallDrivingSkillTooltipValue,
            dStyleValueTooltipValue: dStyleValueTooltipValue,
            dStateValueTooltipValue: dStateValueTooltipValue,
            kmsDrivenTooltipValue: kmsDrivenTooltipValue,
            mobileCallValueTooltipValue: mobileCallValueTooltipValue,
            mobileScreenValueTooltipValue: mobileScreenValueTooltipValue,
            abcPointTooltipValue: abcPointTooltipValue,
            totalAccelerationCount: totalAccelerationCount,
            totalBrakingCount: totalBrakingCount,
            totalCorneringCount: totalCorneringCount,
            totalOverSpeedDuration: totalOverSpeedDuration,
            totalMobileUseInAcceptedCount: totalMobileUseInAcceptedCount,
            totalMobileUseInAcceptedDistance: totalMobileUseInAcceptedDistance,
            totalMobileUseInAcceptedDuration: totalMobileUseInAcceptedDuration,
            totalMobileScreenScreenOnCount: totalMobileScreenScreenOnCount,
            totalMobileScreenScreenOnDistance:
              totalMobileScreenScreenOnDistance,
            totalMobileScreenScreenOnDuration:
              totalMobileScreenScreenOnDuration,
            totalOverSpeedCount: totalOverSpeedCount,
            totalMediumRiskCount: totalMediumRiskCount,
            totalHighRiskCount: totalHighRiskCount,
            tKiloMeter: tKiloMeter,
            tTravelTime: tTravelTime,
            rideCategory: rideCategory,
            startTime: startTime,
            endTime: endTime,
            batterylevelStart: batterylevelStart,
            batterylevelEnd: batterylevelEnd,
            batterylevelTotalValue: (batterylevelEnd - batterylevelStart),
            event: shortEvent,
            longEvent: longEvent,
            totalOverSpeedDurationTV: totalOverSpeedDurationTV,
            totalMobileScreenScreenOnDurationTV:
              totalMobileScreenScreenOnDurationTV,
            totalMobileUseInAcceptedDurationTV:
              totalMobileUseInAcceptedDurationTV,
            tKiloMeterTV: tKiloMeterTV,
            tTravelTimeTV: tTravelTimeTV,
            totalEfficiencyTV: totalEfficiencyTV,
            cppLevel: cppFunction(cpp).value,
            cppLevelColor: cppFunction(cpp).colorCode,
            crashProbabilityScore: crashProbabilityScore,
            mobileUsage: ValueFormat.parseAsFloat(mobileUsage, 0),
            summaryRide:summaryRide,
            day: dayPercentage,
            night: nightPercentage,           
            urbanKilometers: urbanKilometer,
            ruralKilometers: ruralKilometer,
            highwayKilometers: highwayKilometer,
          };
          updatedTableData.push(resultObject);
        }
        setApiData(updatedTableData);
        // console.log(updatedTableData, "updatedTableDataupdatedTableData");
      }
    } catch (e) {
      console.log("Cath Error While Getting Ride List: ", e);
    }
    if (indexStartOfSet == 0) {
      setPrevShow(false);
    } else if (indexStartOfSet > 0) {
      setPrevShow(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault(); // Prevent page reload
      inputRef.current.click(); // Trigger the button click
    }
  };

  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) setCount((prev) => prev + 1);
    else setIndexStart(0);
  }, [filter, workFilter, categoryValue, startDateTime, endDateTime]);

  useEffect(() => {
    if (count > 0) newRideListGet(indexStart);
  }, [indexStart, count]);

  const handleSearch = (e) => {
    e.preventDefault();
    newRideListGet(0);
  };

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const cppFunction = (data) => {
    let value = "";
    let colorCode = "";
    let levelDescription = "";

    if (data > 0 && data <= 50) {
      value = "L1";
      colorCode = "#ff9b9b";
      levelDescription = "Range (1-50)";
    } else if (data > 50 && data <= 100) {
      value = "L2";
      colorCode = "#ff8585";
      levelDescription = "Range (50-100)";
    } else if (data > 100 && data <= 150) {
      value = "L3";
      colorCode = "#ff6e6e";
      levelDescription = "Range (100-150)";
    } else if (data > 150 && data <= 200) {
      value = "L4";
      colorCode = "#ff5757";
      levelDescription = "Range (150-200)";
    } else if (data > 200 && data <= 250) {
      value = "L5";
      colorCode = "#ff4040";
      levelDescription = "Range (200-250)";
    } else if (data == 0) {
      value = "L0";
      colorCode = "#7ABA78";
      levelDescription = "Range (0)";
    } else if (data > 250) {
      value = "L6";
      colorCode = "#ff2929";
      levelDescription = "Range (>250)";
    } else {
      value = "-";
      colorCode = "#ffff";
      levelDescription = "No point available";
    }

    return { value, colorCode, levelDescription };
  };

  // --------------------------------------------------------------------//

  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Rides" breadcrumbItem="Rides" />
        <Row className="mt-2">
          <Col className="col-12 mt-2">
            <Card>
              <CardHeader className="mb-4" style={{ padding: "0px" }}>
                <Row>
                  <Col
                    xl={2}
                    lg={2}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Ride
                      </h4>
                    </div>
                    <div className="mt-4 mb-4" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        data-tooltip-id="allrides"
                        className={
                          workFilter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("")}
                      >
                        All
                        <ReactTooltip
                          id="allrides"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        data-tooltip-id="wrkrides"
                        className={
                          workFilter == "WORK"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("WORK")}
                      >
                        Work
                        <ReactTooltip
                          id="wrkrides"
                          place="top"
                          content="Work"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="perrides"
                        className={
                          workFilter == "PERSONAL"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("PERSONAL")}
                      >
                        Personal
                        <ReactTooltip
                          id="perrides"
                          place="top"
                          content="Personal"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>
                  <Col
                    xl={2}
                    lg={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      padding: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Category
                      </h4>
                    </div>

                    <div className="mt-4 mb-4" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        data-tooltip-id="Categoryall"
                        className={
                          categoryValue == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setCategory("")}
                      >
                        All
                        <ReactTooltip
                          id="Categoryall"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        data-tooltip-id="Categorydriver"
                        className={
                          categoryValue == "RIDE_DRIVER"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setCategory("RIDE_DRIVER")}
                      >
                        Driver
                        <ReactTooltip
                          id="Categorydriver"
                          place="top"
                          content="Driver"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        data-tooltip-id="CategoryPassenger"
                        className={
                          categoryValue == "RIDE_PASSENGER"
                            ? "btn btn-primary btn-sm me-0"
                            : "btn btn-soft-secondary btn-sm me-0"
                        }
                        onClick={() => setCategory("RIDE_PASSENGER")}
                      >
                        Passenger
                        <ReactTooltip
                          id="CategoryPassenger"
                          place="top"
                          content="Passenger"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    style={{ borderRight: "1px solid #DFDEDE", padding: 0 }}
                  >
                    <div className="ms-auto ">
                      <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                        <h4
                          className="card-title mb-1 mt-1"
                          style={{ textAlign: "center" }}
                        >
                          Duration
                        </h4>
                      </div>
                      <div
                        className="mt-4 mb-4"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className={
                            filter == ""
                              ? "btn btn-primary btn-sm me-1"
                              : "btn btn-soft-secondary btn-sm me-1"
                          }
                          onClick={() => setFilter("")}
                          data-tooltip-id="alal"
                        >
                          All
                          <ReactTooltip
                            id="alal"
                            place="top"
                            content="All"
                            style={{ background: "black" }}
                          />
                        </button>
                        <button
                          type="button"
                          className={
                            filter == "CURRENT_DAY" && !modalIsOpen
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_DAY")}
                          data-tooltip-id="cdcd"
                        >
                          CD
                          <ReactTooltip
                            id="cdcd"
                            place="top"
                            content="Current Day"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            filter == "CURRENT_WEEK" && !modalIsOpen
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_WEEK")}
                          data-tooltip-id="cwcw"
                        >
                          CW
                          <ReactTooltip
                            id="cwcw"
                            place="top"
                            content="Current Week"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            filter == "CURRENT_MONTH" && !modalIsOpen
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_MONTH")}
                          data-tooltip-id="cmcm"
                        >
                          CM
                          <ReactTooltip
                            id="cmcm"
                            place="top"
                            content="Current Month"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            filter == "CURRENT_YEAR" && !modalIsOpen
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_YEAR")}
                          data-tooltip-id="cycy"
                        >
                          CY
                          <ReactTooltip
                            id="cycy"
                            place="top"
                            content="Current Year"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            filter == "CUSTOM_DURATION" || modalIsOpen
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => {
                            setIsOpen(true);
                          }}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock} />
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                      </div>
                    </div>

                    {/* <Datepicker/> */}
                  </Col>

                  <Col
                    xl={5}
                    lg={4}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      padding: 0,
                      marginLeft: "-8px",
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="GlobalSearchRide"
                      >
                        Global Search
                        <ReactTooltip
                          id="GlobalSearchRide"
                          place="top"
                          content="Global Search"
                          style={{ background: "black", zIndex: "100" }}
                        />
                      </h4>
                    </div>

                    <div
                      className="my-3"
                      style={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "15px",
                      }}
                    >
                      <input
                        type="text"
                        style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                        value={searchText}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        placeholder="Ride number or Ride name"
                      />
                      <button
                        ref={inputRef}
                        style={{ marginLeft: "5px" }}
                        className="btn btn-primary me-3"
                        // type="button"
                        onClick={handleSearch}
                      >
                        <i className="bx bx-search-alt align-middle"></i>
                      </button>
                    </div>
                  </Col>

                  {/* <Col
                                        xl={2}
                                        lg={2}
                                        style={{ padding: "0px", marginLeft: "-10px" }}
                                    >
                                        <div>
                                            <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                                                <h4
                                                    className="card-title mb-1 mt-1"
                                                    style={{ textAlign: "center" }}
                                                    data-tooltip-id="dwn"
                                                >
                                                    Download
                                                    <ReactTooltip
                                                        id="dwn"
                                                        place="top"
                                                        content="Current Year Rides as Excel"
                                                        style={{ background: "black" }}
                                                    />
                                                </h4>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <div
                                                    className="mt-3 mb-3"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <div style={{ marginTop: "2px" }}>
                                                        <CSVLink
                                                            data={excelData}
                                                            type="button"
                                                            data-tooltip-id="TripReportTool"
                                                            className="btn btn-soft-secondary btn-sm me-3"
                                                            filename={
                                                                loginType == "ADMIN" ||
                                                                    loginType == "SUPER_ADMIN"
                                                                    ? userFirstName + "'s" + " Trip report.csv"
                                                                    : userName + "'s" + " Trip report.csv"
                                                            }
                                                        >
                                                            <img
                                                                src={tripIcon}
                                                                alt="addIcons"
                                                                style={{ height: "30px", width: "30px" }}
                                                            />
                                                            <ReactTooltip
                                                                id="TripReportTool"
                                                                place="top"
                                                                content="Trip Report"
                                                                style={{ background: "black" }}
                                                            />
                                                        </CSVLink>
                                                    </div>
                                                    <div style={{ marginTop: "2px" }}>
                                                        <CSVLink
                                                            data={ddData}
                                                            type="button"
                                                            className="btn btn-soft-secondary btn-sm me-1"
                                                            filename={
                                                                loginType == "ADMIN" ||
                                                                    loginType == "SUPER_ADMIN"
                                                                    ? userFirstName + "'s" + " km/hr report.csv"
                                                                    : userName + "'s" + " km/hr report.csv"
                                                            }
                                                            data-tooltip-id="distanceDurationReport"
                                                        >
                                                            <img
                                                                src={distanceIcon}
                                                                alt="addIcons"
                                                                style={{ height: "30px", width: "30px" }}
                                                            />
                                                            <ReactTooltip
                                                                id="distanceDurationReport"
                                                                place="top"
                                                                content="Distance / Duration Report"
                                                                style={{ background: "black" }}
                                                            />
                                                        </CSVLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col> */}
                </Row>
              </CardHeader>

              <CardBody style={{ padding: "0px" }}>
                <Card className="border-0">
                  <CardHeader
                    className="cardHeader"
                    style={{ paddingLeft: "12px" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <h5 className="sub_heading_new m-0">Driving Score</h5>
                        <ReactTooltip
                          id={"info"}
                          place="top"
                          // content={`
                          // Driver Behaviour Score is calculated on a scale of 100 and represents a cumulative sum of the
                          // Driving Point, Anticipation Point, Self Confidence Point, Driving Skill Point, Driving Style Point, Driver State Point, Mobile Use Point, Mobile Call Point, Overspeed KMS Driven Point, and Acc/Brak/Cor Point
                          // `}
                          multiline={true}
                          style={{
                            backgroundColor: "black",
                            height: "auto",
                            width: "40vw",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            padding: "20px",
                          }}
                        />
                      </div>

                      {/* <div className="ms-auto">
                                                <div style={{ verticalAlign: "middle", display: "flex" }}>

                                                    <div>
                                                        <div className="d-flex mt-1 align-items-center" >
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#E21212' }} />
                                                                Poor (0-80)
                                                            </div>
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#FFBF00' }} />
                                                                Fair (80-93)
                                                            </div>
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#19543E' }} />
                                                                Good (93-100)
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> */}
                    </div>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div
                      className="mb-4"
                      // style={{ height: 525, overflowY: "auto" }}
                    >
                      <div
                        style={{
                          height: "auto",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          padding: "10px",
                        }}
                      >
                        {/* {displayText.DRIVER_BEHAVIOUR_DESCRIPTION} */}
                      </div>
                      <Table bordered style={TableStyle.table}>
                        <thead>
                          {/* <tr>
                                                        
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Rating</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Driving<br />Score</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Ride <br />Name</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="4">Driving Risk Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="7">Driving Behavior Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Travel<br />Period</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Battery %</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Event</th>
                                                    </tr> */}
                          <tr>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Ride<br/>Details
                              {/* <br/><br/><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/> Score
                              {/* <br/><br/><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>
                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/><br/><br/><img src={ride_name} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/>Risk
                              {/* <br /><img src={category_image} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>

                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Driving <br />Points<br /><img src={UBIDrivingScore} alt="UBIDrivingScore" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Risk
                              <br />
                              Data
                              {/* <img src={UBIAnticipation} alt="UBIAnticipation" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/>Self <br />Confidence <br /><img src={UBISelfConfidence} alt="UBISelfConfidence" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/>Driving <br />Skill <br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving <br />
                              Behaviour
                              {/* <img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Behaviour <br />
                              Data
                              {/* <img src={UBIDrivingStyle} alt="UBIDrivingStyle" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Crash <br />
                              Probability
                              {/* <img src={UBIDrivingState} alt="UBIDrivingState" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                           
                           
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Acc/Brak/Cor
                              <br />
                              Counts
                              {/* <img src={UBIKmsDriven} alt="UBIKmsDriven" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Over Speed
                              <br />
                              Data
                              {/* <img src={UBIStressStrain} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Mobile
                              <br />
                              Usage
                              {/* <img src={UBIMobileCallUse} alt="UBIMobileCallUse" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Red Risk <br />
                              Data
                              {/* <img src={UBIMobileScreenUse} alt="UBIMobileScreenUse" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Others
                              {/* <img src={clock} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Context
                              {/* <img src={clock} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                          </tr>
                        </thead>
                        {dataLength > 0 && (
                          <tbody>
                            {(apiData || []).map((apiData, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                 <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                  <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={UBIInfoIcon}
                                        alt="icons2"
                                        style={{
                                          height: "20px",
                                          marginLeft: "-2px",
                                        }}
                                        data-tooltip-id={`SUMMARY${index}`}
                                      />
                                      <ReactTooltip
                                      id={`SUMMARY${index}`}
                                      place="top"
                                      content={apiData.summaryRide}
                                      style={{ backgroundColor: "black" }}
                                    />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "12px",
                                        fontSize: "11px",
                                      }}
                                    >
                                  <Link
                                    to={`/ridedetails?userId=${encodeURIComponent(
                                      Converter.encrypt(apiData.userId)
                                    )}&divisionId=${encodeURIComponent(
                                      Converter.encrypt(apiData.divisionId)
                                    )}&rideId=${encodeURIComponent(
                                      Converter.encrypt(apiData.rideId)
                                    )}&rideCategory=${encodeURIComponent(
                                      Converter.encrypt(apiData.rideCategory)
                                    )}`}
                                  >
                                    <p
                                      style={{
                                        fontSize: "11px",
                                        color: "#5156BE",
                                        marginBottom: "0px",
                                        fontWeight: 700,
                                        cursor:"pointer"
                                      }}
                                    >{`${apiData.rideName}`}</p>
                                  </Link>
                                  </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tKiloMeter + " Kms"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tTravelTime + " Mins"}
                                    </div>
                                  </div>
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={steeringWheel}
                                        alt="icons2"
                                        style={{ height: "20px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "10px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.calculatedDrivingScore >= 0 &&
                                      apiData.calculatedDrivingScore <= 80
                                        ? "Poor"
                                        : apiData.calculatedDrivingScore >=
                                            81 &&
                                          apiData.calculatedDrivingScore <= 93
                                        ? "Fair"
                                        : "Good"}
                                    </div>
                                  </div> */}
                                </td>
                                {/* <td style={{ textAlign: "center", verticalAlign: "middle" }}>{apiData.calculatedDrivingScore >= 0 && apiData.calculatedDrivingScore <= 80 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Poor</p><button className="btn" style={{ backgroundColor: '#E21212' }} /></div> :
                                                                    apiData.calculatedDrivingScore >= 81 && apiData.calculatedDrivingScore <= 93 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Fair</p><button className="btn" style={{ backgroundColor: '#FFBF00' }} /></div> :
                                                                        <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Good</p><button className="btn" style={{ backgroundColor: '#19543E' }} /></div>}
                                                                </td> */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                  // data-tooltip-id={`ETVN${index}`}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.calculatedDrivingScore}
                                        text={`${apiData.calculatedDrivingScore}%`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:
                                            apiData.calculatedDrivingScore >=
                                              0 &&
                                            apiData.calculatedDrivingScore <= 80
                                              ? "#E21212"
                                              : apiData.calculatedDrivingScore >=
                                                  81 &&
                                                apiData.calculatedDrivingScore <=
                                                  93
                                              ? "#FFBF00"
                                              : "#19543e",
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                    {/* <ReactTooltip
                                      id={`ETVN${index}`}
                                      place="top"
                                      content={apiData.totalEfficiencyTVNew}
                                      style={{ backgroundColor: "black" }}
                                    /> */}
                                    
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                     justifyContent:"center",
                                     marginTop:"20px"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={steeringWheel}
                                        alt="icons2"
                                        style={{ height: "20px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",                                        
                                        fontSize: "11px",
                                        marginLeft:"5px"
                                        
                                      }}
                                    >
                                      {apiData.calculatedDrivingScore >= 0 &&
                                      apiData.calculatedDrivingScore <= 50
                                        ? "Bad"
                                        : apiData.calculatedDrivingScore >
                                            50 &&
                                          apiData.calculatedDrivingScore <= 80
                                        ? "Dangerous"
                                        : apiData.calculatedDrivingScore > 80 && apiData.calculatedDrivingScore <= 93 ? "Quiet Safe" : apiData.calculatedDrivingScore > 93 && apiData.calculatedDrivingScore <= 100 ? "Good" : ""}
                                    </div>
                                  </div>
                                </td>
                                {/* <td className="lightBlueColor text-nowrap" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                    <Link
                                                                        to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(apiData.userId))}&divisionId=${encodeURIComponent(Converter.encrypt(apiData.divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}&rideCategory=${encodeURIComponent(Converter.encrypt(apiData.rideCategory))}`}>
                                                                        <span
                                                                            data-tooltip-id={`rideTooltip_${index}`}
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            {apiData.rideName}
                                                                        </span>
                                                                    </Link>
                                                                    <ReactTooltip
                                                                        id={`rideTooltip_${index}`}
                                                                        place="top"
                                                                        effect="solid"
                                                                        backgroundColor="black"
                                                                        content={apiData.StatusTemp}
                                                                    />
                                                                </td> */}
                                {/* <td className="lightBlueColor text-nowrap" data-tooltip-id={`categoryTooltip_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.categoryTooltip}`} */}
                                {/* <ReactTooltip
                                                                        id={`categoryTooltip_${index}`}
                                                                        place="top"
                                                                        effect="solid"
                                                                        backgroundColor="black"
                                                                        content={apiData.categoryTooltip}
                                                                    /> */}
                                {/* </td> */}
                                <td
                                  className="text-nowrap"
                                  data-tooltip-id={`categoryTooltip_${index}`}
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${ValueFormat.formatDecimalIfRounded(
                                      apiData.drivingScore
                                    )}`,
                                  }} // This will render the <br/> as an actual line break
                                />
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{"-"}</div></td> */}
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{"-"}</div></td> */}
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIAnticipation}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Anticipation
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.overallAnticipation
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBISelfConfidence}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "7px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Self Confidence
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallSelfConfidence
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingSkill}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Skill
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallDrivingSkill
                                      )}
                                    </div>
                                  </div>
                                </td>
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallSelfConfidence)}`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallDrivingSkill)} `}</div></td> */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                    fontSize: "11px",
                                  }}
                                >
                                  <div style={{fontSize: "13px"}}>{`${apiData.drivingBehaviourScore}`}</div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingStyle}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Style
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.DSTP
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingState}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving State
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.DSP
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIMobileCallUse}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "9px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Mobile Usage
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.mobileUsage}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Over Speed
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.totalKmSPoint
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIStressStrain}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Acc/Brak/Cor
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.ABCPoint
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.cppLevel}
                                        text={`${apiData.cppLevel}`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:apiData.cppLevelColor,                                          
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                    
                                   
                                  </div>
                                  <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                      <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"20px",
                                        marginBottom:"0px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L0 is Safe</p>

                                    </div>
                                    
                                      
                                    </div>
                                    <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"-5px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L6 is Unsafe</p>

                                    </div>
                                    </div>
                                  {/* <div>
                                    Level{" - "}
                                    {apiData.cppLevel}
                                  </div> */}
                                  {/* <div style={{ marginTop: "15px" }}>
                                    Point(s){" - "}
                                    {apiData.crashProbabilityScore || 0}
                                  </div> */}
                                </td>
                                
                               
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={AccelerationIcon}
                                          alt="totalAccelerationCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Acc
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalAccelerationCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={BreakingIcon}
                                          alt="totalBrakingCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Brak
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalBrakingCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={CorneringIcon}
                                          alt="totalCorneringCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Cor
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalCorneringCount}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      
                                      {apiData.totalOverSpeedCount}{" "}{"Count"}
                                    </div>
                                  </div>           

                                
                                  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalOverSpeedDuration}{" "}{"Mins"}
                                    </div>
                                  </div>
                                  {/* <div style={{ marginTop: "15px" }}>
                                    Duration{" - "}
                                    
                                  </div> */}
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                   <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={ScreenCountIcon}
                                        alt="icons2"
                                        style={{ height: "24px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnCount}{" "}{"Count"}
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnDuration}{" "}{"Mins"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CallCountIconNewOne}
                                        alt="icons2"
                                        style={{ height: "26px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Count"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Mins"}
                                    </div>
                                  </div>



                                  


                                  {/* <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={ScreenCountIcon}
                                        alt="icons2"
                                        style={{ height: "24px" }}
                                      />
                                    </div>
                                    <div style={{ marginLeft: "7px",fontSize: "11px", }}>
                                     {" "}
                                      {apiData.totalMobileScreenScreenOnCount}
                                      <br />
                                      {" "}
                                      {
                                        apiData.totalMobileScreenScreenOnDuration
                                       }{" "}{"Mins"}
                                    </div>
                                  </div> */}
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CallCountIcon}
                                        alt="icons2"
                                        style={{ height: "26px" }}
                                      />
                                    </div>
                                    <div style={{ marginLeft: "7px" ,fontSize: "11px",}}>
                                      {" "}
                                      {apiData.totalMobileUseInAcceptedDuration}
                                      <br />
                                     {" "}
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Mins"}
                                    </div>
                                  </div> */}
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {"-"}
                                    </div>
                                  </div>           

                                  
                                  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {"-"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {"-"}
                                    </div>
                                  </div>
                                  {/* <div style={{ marginTop: "15px" }}>
                                    Medium{" - "}
                                    {apiData.totalMediumRiskCount}
                                  </div> */}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div style={{display:"flex",marginBottom:"0px"}}>
                                    <div>
                                  <img
                                    data-tooltip-id={`time_${index}`}
                                    data-tooltip-html={`Start Time: ${apiData.startTime} <br/> End Time: ${apiData.endTime}`}
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                  />
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px"}}>RD</div>
                                          </div>
                                 <div style={{display:"flex",marginTop: "10px",marginBottom:"0px"}}>
                                 <div>
                                  <img
                                    data-tooltip-id={`battery_${index}`}
                                    data-tooltip-html={`Battery Consumption | ${apiData.batterylevelTotalValue}%`}
                                    src={battery}
                                    alt="icons1"
                                    style={{                                     
                                      height: "25px",
                                    }}
                                  />
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px"}}>BC</div>
                                  </div>
                                  <div style={{display:"flex",marginTop: "10px",marginBottom:"0px"}}>
                                    <div>
                                  <Link
                                    to="/journeyView"
                                    state={{
                                      userId: apiData.userId,
                                      divisionId: apiData.divisionId,
                                      rideId: apiData.rideId,
                                      rideNo: apiData.rideNo,
                                    }}
                                    className="m-0 p-0"
                                  >
                                  <img
                                    src={DetailViewd}
                                    alt="icons1"
                                    style={{
                                      // marginTop: "15px",
                                      height: "25px",
                                      cursor:"pointer"
                                    }}
                                  />
                                  </Link>
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px",marginLeft:"2px"}}>JV</div>
                                  </div>
                                    
                                  {/* <img
                                    data-tooltip-id={`event_${index}`}
                                    src={BatteryCosumIcon}
                                    alt="icons1"
                                    style={{
                                      marginTop: "15px",
                                      height: "25px",
                                    }}
                                  /> */}
                                 <div>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {apiData.event}
                                        </p>

                                 </div>
                                  
                                 
                                  <ReactTooltip
                                    id={`time_${index}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    multiline={true}
                                    style={{ textAlign: "left" }}
                                  />
                                  <ReactTooltip
                                    id={`event_${index}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    content={apiData.longEvent}
                                  />
                                  <ReactTooltip
                                    id={`battery_${index}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    multiline={true}
                                    style={{ textAlign: "left" }}
                                  />
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                 
                                  <div style={{display:"flex"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            fontWeight:700
                                            // marginTop: "10px",
                                          }}
                                        >
                                          U{" - "}
                                        </p>
                                        
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.urbanKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>
                                  <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            // marginTop: "10px",
                                            fontWeight:700
                                          }}
                                        >
                                          R{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.ruralKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>
                                  
                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700
                                          }}
                                        >
                                          H{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.highwayKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>

                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700
                                          }}
                                        >
                                          D{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.day}{" "}{"%"}
                                        </p>

                                 </div>         
                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 
                                  <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700
                                          }}
                                        >
                                          N{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.night}{" "}{"%"}
                                        </p>

                                 </div>                                  
                                 
                                  
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>

                      {dataLength === 0 && (
                        <p
                          className="data_not_available"
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card>

                {/* <Card className="border-0">
                  <CardHeader className="cardHeader">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="sub_heading m-0">
                        Dashboard for Violation
                      </h5>
                      <div className="ms-auto">
                        <div
                          style={{ verticalAlign: "middle", display: "flex" }}
                        >
                          <div>
                            <div className="d-flex mt-1 align-items-center">
                              <div
                                className="me-2 d-flex align-items-center"
                                style={{ fontSize: "14px" }}
                              >
                                <button
                                  className="btn me-1"
                                  style={{ backgroundColor: "#E21212" }}
                                />
                                Poor (0-80)
                              </div>
                              <div
                                className="me-2 d-flex align-items-center"
                                style={{ fontSize: "14px" }}
                              >
                                <button
                                  className="btn me-1"
                                  style={{ backgroundColor: "#FFBF00" }}
                                />
                                Fair (80-93)
                              </div>
                              <div
                                className="me-1 d-flex align-items-center"
                                style={{ fontSize: "14px" }}
                              >
                                <button
                                  className="btn me-1"
                                  style={{ backgroundColor: "#19543E" }}
                                />
                                Good (93-100)
                              </div>
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div style={{ height: 425, overflowY: "auto" }}>
                      <Table bordered style={TableStyle.table}>
                        <thead>
                          <tr>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Rating
                              <br />
                              <br />
                              <img
                                src={i3}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving
                              <br />
                              Score
                              <br />
                              <img
                                src={UBIScoreImage}
                                alt="UBIStressStrain"
                                style={{ marginTop: "5px", height: "23px" }}
                              />{" "}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Ride <br />
                              Name
                              <br />
                              <img
                                src={ride_name}
                                alt="UBIStressStrain"
                                style={{ marginTop: "5px", height: "23px" }}
                              />{" "}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Total Distance <br /> (kms) <br />
                              <img
                                src={tdistance}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Total Duration <br /> (Mins) <br />
                              <img
                                src={tduration}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Crash <br /> (Count) <br />
                              <img
                                src={crash}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              data-tooltip-id="HRAC"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              High Risk
                              <br /> (Count) <br />
                              <img
                                src={i5}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              data-tooltip-id="MRAC"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Medium Risk
                              <br /> (Count)
                              <br />
                              <img
                                src={i6}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              data-tooltip-id="TOC"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Overspeed
                              <br /> (Mins | Count)
                              <br />
                              <img
                                src={i7}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              data-tooltip-id="TMSC"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Mobile Screen
                              <br />
                              (Mins | Count) <br />
                              <img
                                src={i9}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              data-tooltip-id="TMCC"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Mobile Call
                              <br />
                              (Mins | Count) <br />
                              <img
                                src={i8}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              data-tooltip-id="TAC"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Acceleration
                              <br />
                              (Count) <br />
                              <img
                                src={i10}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              data-tooltip-id="TBC"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Braking <br />
                              (Count) <br />
                              <img
                                src={i11}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              data-tooltip-id="TCC"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Cornering
                              <br />
                              (Count) <br />
                              <img
                                src={i12}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              data-tooltip-id="TCC"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Journey
                              <br />
                              <br />
                              <img
                                src={DetailViewd}
                                alt="icons1"
                                style={{ marginTop: "5px", height: "20px" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <ReactTooltip
                          id="HRAC"
                          place="top"
                          content="Total High Risk Alert Count"
                          style={{ backgroundColor: "black" }}
                        />
                        <ReactTooltip
                          id="MRAC"
                          place="top"
                          content="Total Medium Risk Alert Count"
                          style={{ backgroundColor: "black" }}
                        />
                        <ReactTooltip
                          id="TOC"
                          place="top"
                          content="Total Overspeed Count"
                          style={{ backgroundColor: "black" }}
                        />
                        <ReactTooltip
                          id="TMCC"
                          place="top"
                          content="Total Mobile Call Duration | Count"
                          style={{ backgroundColor: "black" }}
                        />
                        <ReactTooltip
                          id="TMSC"
                          place="top"
                          content="Total Mobile Screen Duration | Count"
                          style={{ backgroundColor: "black" }}
                        />
                        <ReactTooltip
                          id="TAC"
                          place="top"
                          content="Total Acceleration Count"
                          style={{ backgroundColor: "black" }}
                        />
                        <ReactTooltip
                          id="TBC"
                          place="top"
                          content="Total Braking Count"
                          style={{ backgroundColor: "black" }}
                        />
                        <ReactTooltip
                          id="TCC"
                          place="top"
                          content="Total Cornering Count"
                          style={{ backgroundColor: "black" }}
                        />

                        {dataLength > 0 && (
                          <tbody>
                            {(apiData || []).map((apiData, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {apiData.calculatedDrivingScore >= 0 &&
                                  apiData.calculatedDrivingScore <= 80 ? (
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "10px" }}
                                      >
                                        Poor
                                      </p>
                                      <button
                                        className="btn"
                                        style={{ backgroundColor: "#E21212" }}
                                      />
                                    </div>
                                  ) : apiData.calculatedDrivingScore >= 81 &&
                                    apiData.calculatedDrivingScore <= 93 ? (
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "10px" }}
                                      >
                                        Fair
                                      </p>
                                      <button
                                        className="btn"
                                        style={{ backgroundColor: "#FFBF00" }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "10px" }}
                                      >
                                        Good
                                      </p>
                                      <button
                                        className="btn"
                                        style={{ backgroundColor: "#19543E" }}
                                      />
                                    </div>
                                  )}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    verticalAlign: "middle",
                                  }}
                                  data-tooltip-id={`ETV${index}`}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 40,
                                        width: 40,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.calculatedDrivingScore}
                                        text={`${apiData.calculatedDrivingScore}%`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "30px",
                                          pathColor:
                                            apiData.calculatedDrivingScore >=
                                              0 &&
                                            apiData.calculatedDrivingScore <= 80
                                              ? "#E21212"
                                              : apiData.calculatedDrivingScore >=
                                                  81 &&
                                                apiData.calculatedDrivingScore <=
                                                  93
                                              ? "#FFBF00"
                                              : "#19543e",
                                          trailColor: "#0095FF",
                                        })}
                                      ></CircularProgressbar>
                                    </div>
                                    <ReactTooltip
                                      id={`ETV${index}`}
                                      place="top"
                                      content={apiData.totalEfficiencyTVNew}
                                      style={{ backgroundColor: "black" }}
                                    />
                                  </div>
                                </td>
                                <td
                                  className="lightBlueColor text-nowrap"
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <Link
                                    to={`/ridedetails?userId=${encodeURIComponent(
                                      Converter.encrypt(apiData.userId)
                                    )}&divisionId=${encodeURIComponent(
                                      Converter.encrypt(apiData.divisionId)
                                    )}&rideId=${encodeURIComponent(
                                      Converter.encrypt(apiData.rideId)
                                    )}&rideCategory=${encodeURIComponent(
                                      Converter.encrypt(apiData.rideCategory)
                                    )}`}
                                  >
                                    <span
                                      data-tooltip-id={`rideTooltip_${index}`}
                                      style={{ fontSize: "14px" }}
                                    >
                                      {apiData.rideName}
                                    </span>
                                  </Link>
                                  <ReactTooltip
                                    id={`rideTooltip_${index}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    content={apiData.StatusTemp}
                                  />
                                </td>

                                <td
                                  className="lightBlueColor"
                                  data-tooltip-id={`tkilometer_${index}`}
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    fontSize: "14px",
                                  }}
                                >
                                  {apiData.tKiloMeter}
                                  <ReactTooltip
                                    id={`tkilometer_${index}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    content={apiData.tKiloMeterTV}
                                  />
                                </td>
                                <td
                                  className="lightBlueColor"
                                  data-tooltip-id={`tTravelTime_${index}`}
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    fontSize: "14px",
                                  }}
                                >
                                  {apiData.tTravelTime}
                                  <ReactTooltip
                                    id={`tTravelTime_${index}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    content={apiData.tTravelTimeTV}
                                  />
                                </td>

                                <td
                                  className=""
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    fontSize: "14px",
                                  }}
                                >
                                  {apiData.crashCount}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    verticalAlign: "middle",
                                  }}
                                >{`${apiData.totalHighRiskCount}`}</td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    verticalAlign: "middle",
                                  }}
                                >{`${apiData.totalMediumRiskCount}`}</td>

                                <td
                                  data-tooltip-id={`overSpeedDurationCount_${index}`}
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {`${apiData.totalOverSpeedDuration} | ${apiData.totalOverSpeedCount}`}
                                  
                                </td>
                                <td
                                  data-tooltip-id={`mobileScreenDurationCount_${index}`}
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {`${apiData.totalMobileScreenScreenOnDuration} | ${apiData.totalMobileScreenScreenOnCount}`}
                                  
                                </td>
                                <td
                                  data-tooltip-id={`mobileCallDurationCount_${index}`}
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {`${apiData.totalMobileUseInAcceptedDuration} | ${apiData.totalMobileUseInAcceptedCount}`}
                                  
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    verticalAlign: "middle",
                                  }}
                                >{`${apiData.totalAccelerationCount}`}</td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    verticalAlign: "middle",
                                  }}
                                >{`${apiData.totalBrakingCount}`}</td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    verticalAlign: "middle",
                                  }}
                                >{`${apiData.totalCorneringCount}`}</td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <Link
                                    to="/journeyView"
                                    state={{
                                      userId: apiData.userId,
                                      divisionId: apiData.divisionId,
                                      rideId: apiData.rideId,
                                      rideNo: apiData.rideNo,
                                    }}
                                    className="m-0 p-0"
                                  >
                                    <span
                                      style={{
                                        fontSize: "13.5px",
                                        textAlign: "left",
                                      }}
                                    >
                                      View
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>
                      {dataLength === 0 && (
                        <p
                          className="data_not_available"
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card> */}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="d-flex justify-content-between mb-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="pagination ">
                      <a>{pageNumber}</a>
                    </div>
                    <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      of
                    </p>
                    <div className="pagination ">
                      <a>{totalPageCount}</a>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      visibility: nextShow ? "visible" : "hidden",
                      float: "hidden",
                    }}
                  >
                    Next
                  </button>
                </>
              ) : null}
            </div>
          </Col>
        </Row>

        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}

export default NewRideList;
