import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { UDMainDashboardTopRowItems } from "../../../common/data/dashboard";
import { widgetUsersData } from "../../../common/data/dashboarduserdata";
import UserDrivingScoreController from "../../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../../util/ValueFormat.js";
import { useSelector } from "react-redux";
import { layoutTypes } from "../../../constants/layout";
import {
  SC_USER_LOGIN_TYPE,
  SC_USER_FIRST_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_TOTAL_RIDE_COUNT,
} from "../../../constant/StorageConstant";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../../components/Common/loader";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import OverAllDrivingScore from "../overAllDrivingScore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./UserDashboardSubMenu.css";
import UserDashboardSubMenu from "./UserDashboardSubMenu.js";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import UserAdminDashboardSubMenu from "./UserAdminDashboardSubMenu.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import UserDashboardColorIndicators from "./UserDashboardColorIndicators.js";
import UserRidesKmHrsScoreCBar from "./UserRidesKmHrsScoreCBar.js";
import ProgressBarUD from "./progressBar.js";
import DriverBehaviourRatingForUser from "./DriverBehaviorRating.js";

const UserDashboard = () => {
  const [UDMainDashboardTopRowItemsTemp, setUDMainDashboardTopRowItemsTemp] =
    useState(UDMainDashboardTopRowItems);
  const [overallDataProps, setOverallDataProps] = useState([]);
  const [UDMainDashboardSafetyTableData, setUDMainDashboardSafetyTableData] =
    useState(widgetUsersData);
  const [topTotalData, setTopTotalData] = useState({
    tRides: 0,
    tKilometers: 0.0,
    tHours: 0.0,
    tRiskAlerts: 0,
    tOtherAlerts: 0,
  });

  const [yearlyDatas, setYearlyDatas] = useState([]);

  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
  }, []);

  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [rideTotalRide, setRideTotalRide] = useState({
    ride: 0,
    totalRides: 0,
  });

  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");
  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));
  const { pathname } = useLocation();
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [getSelectedYearData, setGetSelectedYearData] = useState([]);
  const [lastOverAllSyData, setLastOverAllSyData] = useState([]);
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: "",
  });
  const [aggregateData, setAggregateData] = useState({
    totalRides: 0,
    totalKilometer: 0,
    totalHighRiskCount: 0,
    totalAlertDataCount: 0,
    totalMediumRiskCount: 0,
    totalTravelTime: 0,
  });
  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    if (count == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [filter, startDateTime, endDateTime]);

  const UserDashboardRideBoxCount = {
    totalRides: 0,
    count: [
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 1 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 2 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 3 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 4 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 5 },
      { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 6 },
    ],
  };

  const UserDashboardOverallRideBoxList = [
      { totalRides: 0, rideBox: 1, percentage: 0 },
      { totalRides: 0, rideBox: 2, percentage: 0 },
      { totalRides: 0, rideBox: 3, percentage: 0 },
      { totalRides: 0, rideBox: 4, percentage: 0 },
      { totalRides: 0, rideBox: 5, percentage: 0 },
      { totalRides: 0, rideBox: 6, percentage: 0 },
    ];
  
  const [dataOverallRideBoxList , setDataOverallRideBoxList] = useState(UserDashboardOverallRideBoxList);
// console.log(dataOverallRideBoxList,"dataOverallRideBoxList");

  const [USRideBoxCount, setUSRideBoxCount] = useState(
    UserDashboardRideBoxCount
  );
  const getTotalValues = async () => {
    setShowLoader((current) => current + 1);
    try {
      var result = await UserDrivingScoreController.totalValuesController(
        filters.durationFilter,
        filters.startDateTime,
        filters.endDateTime
      );
      setShowLoader((current) => current - 1);
      var resultJSON = JSON.parse(result);
      var currentMontData = [];

      var status = resultJSON.status;
      console.log(resultJSON, "resultJSONresultJSON");
      
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        var totalRide = 0;
        var totalKm = 0;
        var safeRide = 0;
        let overAllRideBoxTotalRidesCount = {
          totalRides: 0,
          count: [
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 1,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 2,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 3,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 4,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 5,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 0,
              percentage: 0,
              rideBox: 6,
              totalKm: 0,
              totalTravelTime: 0,
            },
          ],
        };
        let totalDays = 0;
        let totalRides = 0;
        let totalKilometer = 0;
        let totalHighRiskCount = 0;
        let totalAlertDataCount = 0;
        let totalMediumRiskCount = 0;
        let totalTravelTime = 0;
        let overSpeedValue = 0;
        let mobileCallValue = 0;
        let mobileScreenValue = 0;
        let severeAccelerationValue = 0;
        let severeCorneringValue = 0;
        let severeBrakingValue = 0;
        let yearlyData = [];
        // console.log("getTotalValues: " , resultJSONData);

        resultJSONData.map((apiResultTemp) => {
          var overallData = ValueFormat.parseAsObject(
            apiResultTemp.overallData
          );
          var currentYearData = ValueFormat.parseAsObject(
            apiResultTemp.currentYearData
          );
          var currentMonthData = ValueFormat.parseAsObject(
            apiResultTemp.currentMonthData
          );
          var currentWeekData = ValueFormat.parseAsObject(
            apiResultTemp.currentWeekData
          );
          var currentDayData = ValueFormat.parseAsObject(
            apiResultTemp.currentDayData
          );
          var overallRideBoxList = ValueFormat.parseAsArray(
            apiResultTemp.overallRideBoxList
          );
          var drivingScoreList = ValueFormat.roundArrayValue(
            ValueFormat.parseAsArray(currentMonthData.drivingScoreList)
          );
          var deviceNameList = ValueFormat.parseAsArray(
            currentMonthData.deviceNameList
          );
          yearlyData.push(
            overallData,
            currentYearData,
            currentMonthData,
            currentWeekData,
            currentDayData
          );
          setYearlyDatas(yearlyData);
          totalDays = parseInt(ValueFormat.parseAsInt(apiResultTemp.noOfDays));
          for (let i = 0; i < drivingScoreList.length; i++) {
            var driveScoreListTemp = drivingScoreList[i];

            var deviceNameListTemp = deviceNameList[i];
            var deviceName = deviceNameListTemp.name;
            var driveScoreResult = { x: deviceName, y: driveScoreListTemp };
            currentMontData.push(deviceName);
          }

          overallRideBoxList.map((apiData) => {
            var rideBox = ValueFormat.parseAsInt(apiData.rideBox);
            totalRide += ValueFormat.parseAsInt(apiData.totalRides);
            if (rideBox == 1) {
              safeRide = apiData.totalRides;
            }
          });

          const overAllRideBoxCountList = ValueFormat.parseAsArray(
            apiResultTemp.overallRideBoxCountList
          );

          overAllRideBoxCountList.forEach((apiData) => {
            totalRides += ValueFormat.parseAsInt(apiData.totalRides);
            totalKilometer +=
              parseFloat(apiData.totalKilometer) == undefined ||
              parseFloat(apiData.totalKilometer) == null
                ? 0
                : parseFloat(apiData.totalKilometer);
            totalHighRiskCount += ValueFormat.parseAsInt(
              apiData.totalHighRiskCount
            );
            totalAlertDataCount += ValueFormat.parseAsInt(
              apiData.totalAlertDataCount
            );
            totalMediumRiskCount += ValueFormat.parseAsInt(
              apiData.totalMediumRiskCount
            );
            totalTravelTime +=
              parseFloat(apiData.totalTravelTime) == undefined ||
              parseFloat(apiData.totalTravelTime) == null
                ? 0
                : parseFloat(apiData.totalTravelTime);
            overSpeedValue += ValueFormat.parseAsInt(apiData.overSpeedCount);
            mobileCallValue += ValueFormat.parseAsInt(apiData.mobileUseCount);
            mobileScreenValue += ValueFormat.parseAsInt(
              apiData.mobileScreenCount
            );
            severeAccelerationValue += ValueFormat.parseAsInt(
              apiData.severeAccelerationCount
            );
            severeCorneringValue += ValueFormat.parseAsInt(
              apiData.severeCorneringCount
            );
            severeBrakingValue += ValueFormat.parseAsInt(
              apiData.severeBrakingCount
            );
          });

          let overAllTotalRides = 0;
          overAllRideBoxCountList.map((val, i) => {
            overAllTotalRides += ValueFormat.parseAsInt(val.totalRides);
          });
          overAllRideBoxTotalRidesCount.totalRides = overAllTotalRides;
          
          overAllRideBoxCountList.map((val, i) => {
            const percentageValue =
              (ValueFormat.parseAsInt(val.totalRides) / overAllRideBoxTotalRidesCount.totalRides) * 100;
          
            // Logic to format the percentage
            let formattedPercentage;
            if (percentageValue % 1 === 0) {
              // If the value is an integer (e.g., 50.00)
              formattedPercentage = percentageValue.toFixed(0);
            } else if (percentageValue * 10 % 1 === 0) {
              // If the value has one significant decimal place (e.g., 50.10, 50.7)
              formattedPercentage = percentageValue.toFixed(1);
            } else {
              // If the value has more than one significant decimal place (e.g., 50.66)
              formattedPercentage = percentageValue.toFixed(2);
            }
          
            console.log(percentageValue, formattedPercentage, "formattedPercentage");
            
            overAllRideBoxTotalRidesCount.count[val.rideBox - 1] = {
              ...overAllRideBoxTotalRidesCount.count[val.rideBox],
              totalRides: val.totalRides,
              totalKm: val.totalKilometer,
              totalTravelTime: val.totalTravelTime,
              rideBox: val.rideBox,
              percentage: formattedPercentage,
            };
          });
          
          setUSRideBoxCount(overAllRideBoxTotalRidesCount);

          setTotalSafeRidePer(
            ValueFormat.parseAsFloat((safeRide / totalRide) * 100, 0)
          );
          setRideTotalRide({
            ride: safeRide,
            totalRides: totalRide,
          });

          setUDMainDashboardTopRowItemsTemp(
            UDMainDashboardTopRowItemsTemp.map((staticObjectTemp) => {
              var isTypeMatched = false;
              var overallValue = 0;
              let mobCall = 0;
              let mobScreen = 0;
              if (staticObjectTemp.type === "OVER_SPEED") {
                isTypeMatched = true;
                // overallValue = ValueFormat.parseAsInt(overallData.totalRides);
                // localStorage.setItem(SC_TOTAL_RIDE_COUNT, overallValue);
                overallValue = ValueFormat.parseAsInt(overSpeedValue);
              } else if (staticObjectTemp.type === "MOBILE_USAGE") {
                isTypeMatched = true;
                mobCall = ValueFormat.parseAsInt(mobileCallValue);
                mobScreen = ValueFormat.parseAsInt(mobileScreenValue);
                overallValue =
                  ValueFormat.parseAsInt(mobCall) +
                  ValueFormat.parseAsInt(mobScreen);
              } else if (staticObjectTemp.type === "ACCELERATION") {
                isTypeMatched = true;
                overallValue = ValueFormat.parseAsInt(severeAccelerationValue);
              } else if (staticObjectTemp.type === "CORNERING") {
                isTypeMatched = true;
                overallValue = ValueFormat.parseAsInt(severeCorneringValue);
              } else if (staticObjectTemp.type === "BRAKING") {
                isTypeMatched = true;
                overallValue = ValueFormat.parseAsInt(severeBrakingValue);
              }

              if (isTypeMatched === true) {
                let staticObjectTempReplace = {
                  overallData: overallValue,
                };
                return { ...staticObjectTemp, ...staticObjectTempReplace };
              } else {
                return staticObjectTemp;
              }
            })
          );
          setDataOverallRideBoxList(overallRideBoxList.map(val => ({
            totalRides: val.totalRides,
            rideBox: val.rideBox,
            percentage: ((ValueFormat.parseAsInt(val.totalRides) / totalRide) * 100).toFixed(1),
          })));
        });
        setAggregateData({
          totalRides,
          totalKilometer,
          totalHighRiskCount,
          totalAlertDataCount,
          totalMediumRiskCount,
          totalTravelTime,
        });
        setTotalDays(totalDays);
      } else {
        console.log("Status failed");
      }
    } catch (e) {
      console.log("error:", e);
    }
  };

  const getSafetyData = async () => {
    try {
      var result = await UserDrivingScoreController.userSafetyDetailController(
        filters.durationFilter,
        filters.startDateTime,
        filters.endDateTime
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("getSafetyData: ", JSON.stringify(resultJSONData));
        var totalRideDataList = [];
        var totalKmsList = [];
        var totalHrsList = [];
        var totalRiskList = [];
        var totalOtherAlertsList = [];
        let totalData = {
          tRides: 0,
          tKilometers: 0.0,
          tHours: 0.0,
          tRiskAlerts: 0,
          tOtherAlerts: 0,
        };
        if (resultJSONData.length > 0) {
          const rideBoxList = resultJSONData[0].rideBoxList;

          rideBoxList.forEach((rideBoxItem) => {
            totalData.tRides += rideBoxItem.totalRides;
            totalData.tKilometers += rideBoxItem.totalKiloMeters;
            totalData.tHours += rideBoxItem.totalHours;
            totalData.tRiskAlerts += rideBoxItem.totalRiskAlerts;
            totalData.tOtherAlerts += rideBoxItem.totalManualDataCount;
          });

          setTopTotalData(totalData);

          for (let categoryCount = 0; categoryCount < 6; categoryCount++) {
            var totalRides = 0;
            var totalKms = 0;
            var totalHrs = 0;
            var totalRiskAlerts = 0;
            var totalOtherAlert = 0;

            let rideBoxList = ValueFormat.parseAsArray(
              resultJSONData[0].rideBoxList
            );

            for (let rideBox = 0; rideBox < rideBoxList.length; rideBox++) {
              var rideBoxValue = ValueFormat.parseAsInt(
                rideBoxList[rideBox].rideBox
              );

              if (rideBoxValue === categoryCount + 1) {
                totalRides = ValueFormat.parseAsInt(
                  rideBoxList[rideBox].totalRides
                );
                totalKms = ValueFormat.parseAsFloat(
                  rideBoxList[rideBox].totalKiloMeters,
                  2
                );
                totalHrs = ValueFormat.parseAsFloat(
                  rideBoxList[rideBox].totalHours,
                  2
                );
                totalRiskAlerts = ValueFormat.parseAsInt(
                  rideBoxList[rideBox].totalRiskAlerts
                );
                totalOtherAlert = ValueFormat.parseAsInt(
                  rideBoxList[rideBox].totalManualDataCount
                );
              }
            }

            totalRideDataList.push(totalRides);
            totalKmsList.push(totalKms);
            totalHrsList.push(totalHrs);
            totalRiskList.push(totalRiskAlerts);
            totalOtherAlertsList.push(totalOtherAlert);
          }

          setUDMainDashboardSafetyTableData(
            UDMainDashboardSafetyTableData.map((staticObjectTemp) => {
              var isTypeMatched = false;
              var rideDataList = [];

              if (staticObjectTemp.type === "RIDE") {
                isTypeMatched = true;
                rideDataList = ValueFormat.parseAsArray(totalRideDataList);
              } else if (staticObjectTemp.type === "DISTANCE") {
                isTypeMatched = true;
                rideDataList = ValueFormat.parseAsArray(totalKmsList);
              } else if (staticObjectTemp.type === "DURATION") {
                isTypeMatched = true;
                rideDataList = ValueFormat.parseAsArray(totalHrsList);
              } else if (staticObjectTemp.type === "ALERT") {
                isTypeMatched = true;
                rideDataList = ValueFormat.parseAsArray(totalRiskList);
              } else if (staticObjectTemp.type === "OTHER ALERT") {
                isTypeMatched = true;
                rideDataList = ValueFormat.parseAsArray(totalOtherAlertsList);
              }

              if (isTypeMatched === true) {
                let staticObjectTempReplace = { dataListValues: rideDataList };
                return { ...staticObjectTemp, ...staticObjectTempReplace };
              } else {
                return staticObjectTemp;
              }
            })
          );
        } else {
          console.log("Data not available");
        }
      } else {
        console.log("Status failed");
      }
    } catch (e) {
      console.log("Catch error: ", e);
    }
  };

  const [summaryData, setSummaryData] = useState([]);
  
  const handleSummaryData = (data) => {
    setSummaryData(data);
  };

  useEffect(() => {
    getTotalValues();
    getSafetyData();
  }, [
    filters.durationFilter,
    indexStart,
    filters.startDateTime,
    filters.endDateTime,
  ]);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const containerIds = ["container1"];

  // useEffect(()=>{
  //   localStorage.removeItem("_grecaptcha")
  // },[])

  //meta title

  const getOverAllDataProps = (data) => {
    setOverallDataProps(data);
  };

  const handleChange = (filterType) => {
    setCount(0);
    setStartDateTime("");
    setEndDateTime("");
    setFilters({
      startDateTime: "",
      endDateTime: "",
      durationFilter: filterType,
    });
  };
  const getSelectedYear = (newData) => {
    setGetSelectedYearData(newData);
  };

  const lastOverAllDataSY = (newData) => {
    setLastOverAllSyData(newData);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div className="ps-2 pe-2"> */}
        <Container fluid id="division">
          <div id="container1">
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
            {showLoader > 0 && <Loader />}
            <Row className="adminmenu-height mb-2">
              <Col
                xs={12}
                className="shadow-sm "
                style={{ position: "fixed", zIndex: 1, background: "#fff" }}
              >
                <Row>
                  <Col xl={5} className="py-2">
                    {loginType == "ADMIN" || loginType == "SUPER_ADMIN" ? (
                      <UserAdminDashboardSubMenu />
                    ) : (
                      <UserDashboardSubMenu />
                    )}
                  </Col>
                  <Col
                    xl={7}
                    className="py-2"
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div className="ms-auto border-end border-secondary">
                        <div style={{ float: "right" }}>
                          {/* <button
                                type="button"
                                className={
                                  filters.durationFilter == ""
                                    ? "btn btn-primary btn-sm me-2"
                                    : "btn btn-soft-secondary btn-sm me-2"
                                }
                                onClick={() => handleChange("")}
                                data-tooltip-id="allBl"
                              >
                                All
                                <ReactTooltip
                                  id="allBl"
                                  place="top"
                                  content="Showing zone summary for all the time"
                                  style={{ background: "black", zIndex: 100 }}
                                />
                              </button> */}
                          <button
                            type="button"
                            className={
                              (filters.durationFilter == "CURRENT_DAY" && !modalIsOpen)
                                ? "btn btn-primary btn-sm me-2"
                                : "btn btn-soft-secondary btn-sm me-2"
                            }
                            onClick={() => handleChange("CURRENT_DAY")}
                            data-tooltip-id="cd"
                          >
                            CD
                            <ReactTooltip
                              id="cd"
                              place="bottom"
                              content="Showing zone summary for current day"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>
                          <button
                            type="button"
                            className={
                              (filters.durationFilter == "CURRENT_WEEK" && !modalIsOpen)
                                ? "btn btn-primary btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => handleChange("CURRENT_WEEK")}
                            data-tooltip-id="cw"
                          >
                            CW
                            <ReactTooltip
                              id="cw"
                              place="bottom"
                              content="Showing zone summary for current week"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              (filters.durationFilter == "CURRENT_MONTH" && !modalIsOpen)
                                ? "btn btn-primary btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => handleChange("CURRENT_MONTH")}
                            data-tooltip-id="cm"
                          >
                            CM
                            <ReactTooltip
                              id="cm"
                              place="bottom"
                              content="Showing zone summary for current month"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              (filters.durationFilter == "CURRENT_YEAR" && !modalIsOpen)
                                ? "btn btn-primary btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => handleChange("CURRENT_YEAR")}
                            data-tooltip-id="cy"
                          >
                            CY
                            <ReactTooltip
                              id="cy"
                              place="bottom"
                              content="Showing zone summary for current year"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              (filters.durationFilter == "CUSTOM_DURATION" || modalIsOpen)
                                ? "btn btn-primary btn-sm me-2"
                                : "btn btn-soft-secondary btn-sm me-2"
                            }
                            onClick={() => {
                              setIsOpen(true);
                              setCount(1);
                            }}
                            data-tooltip-id="custom duration"
                          >
                            <FontAwesomeIcon icon={faClock} />
                            <ReactTooltip
                              id="custom duration"
                              place="bottom"
                              content="Showing zone summary for custom duration"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>
                        </div>
                      </div>

                      {(loginType == "ADMIN" ||
                        loginType == "SUPER_ADMIN" ||
                        loginType == "SUPER_ADMIN_AMAZON") && (
                        <div className="border-end border-secondary pe-2">
                          <Link to={"/myRides"}>
                            <button
                              data-tooltip-id="gtr"
                              className="btn btn-primary btn-sm"
                            >
                              Go To Rides
                              <ReactTooltip
                                id="gtr"
                                place="bottom"
                                content="Go To Rides"
                                style={{
                                  zIndex: 1000,
                                  backgroundColor: "black",
                                }}
                              />
                            </button>
                          </Link>
                        </div>
                      )}

                      <div>
                        <ExportScreenPdf
                          containerIds={containerIds}
                          fileName={
                            loginType == "ADMIN" || loginType == "SUPER_ADMIN"
                              ? userFirstName + "'s" + " Overall Summary"
                              : userName + "'s" + " Overall Summary"
                          }
                        />
                      </div>
                      <div>
                        <ViewPdf
                          // safetyPieData={safetyPieData}
                          // osMcuMsuProps={osMcuMsuProps}
                          // aCBProps={aCBProps}
                          // riskAlertPdf={riskAlertPdf}
                          // leaderBoardProps={leaderBoardProps}

                          overallDataProps={overallDataProps}
                          UDMainDashboardTopRowItemsTemp={
                            UDMainDashboardTopRowItemsTemp
                          }
                          usertotalSafeRidePer={totalSafeRidePer}
                          UDMainDashboardSafetyTableData={
                            UDMainDashboardSafetyTableData
                          }
                          yearlyDatas={yearlyDatas}
                          USRideBoxCount={USRideBoxCount}
                          getSelectedYearData={getSelectedYearData}
                          lastOverAllSyData={lastOverAllSyData}
                          aggregateData={aggregateData}
                          totalDays={totalDays}
                          filtersData={filters}
                          summaryDataUser={summaryData}
                          // totalSafeRidePer={totalSafeRidePer}
                          // rccProps={rccProps}
                          // rideNamesPerCat={rideNamesPerCat}
                          // aCBDataProps={aCBDataProps}
                          // osDataProps={osDataProps}
                          // msuPdfDataProps={msuPdfDataProps}
                          // mcuPdfDataProps={mcuPdfDataProps}
                          // locationDataPdf={locationDataPdf}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <Row className="adminmenu-mt">
              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      padding: "10px 8px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Summary
                  </CardHeader>
                  <CardBody className="p-0 m-0 body_text">
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          {UDMainDashboardTopRowItemsTemp.map((item, index) => (
                            <tr
                              key={index}
                              className=""
                            >
                              <td
                                style={{ padding: "4.7px", paddingLeft: '8px' }}
                              >
                                {item.title}
                                <AiOutlineExclamationCircle
                                  style={{
                                    marginLeft: "5px",
                                    width:"15px",
                                    height:"15px",
                                  }}
                                  data-tooltip-id={
                                    "snpTooltipContenttt" + index
                                  }
                                />
                                <ReactTooltip
                                  id={"snpTooltipContenttt" + index}
                                  place="top"
                                  content={<span>{item.decription}</span>}
                                  style={{ backgroundColor: "black" }}
                                />
                              </td>
                              <td
                                style={{
                                
                                  padding: "4.7px 20px",
                                }}
                              >
                                <span
                                  style={{ float: "right" }}
                                >
                                  {item.overallData}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Green Zone
                  
                  </CardHeader>
                  <CardBody
                    style={{ padding: "10px" }}
                    className="body_text"
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{textAlign: "center" }}
                          >
                            C1
                          </span>
                          <span>
                            {USRideBoxCount.count[0].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[0].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            pathColor: "#19543e",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Safe Driving
                        <br />
                        <span>
                          {USRideBoxCount.count[0].totalRides} /{" "}
                          {USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ textAlign: "center" }}
                          >
                            C2
                          </span>
                          <span >
                            {USRideBoxCount.count[1].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[1].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            pathColor: "#19543e",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Sportive Driving
                        <br />
                        <span >
                          {USRideBoxCount.count[1].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Amber Zone
                    
                  </CardHeader>

                  <CardBody
                    style={{ padding: "10px" }}
                    className="body_text"
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ textAlign: "center" }}
                          >
                            C3
                          </span>
                          <span >
                            {USRideBoxCount.count[2].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[2].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            textSize: "25px",
                            pathColor: "#FFBF00",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Quite Safe Driving
                        <br />
                        <span >
                          {USRideBoxCount.count[2].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ textAlign: "center" }}
                          >
                            C4
                          </span>
                          <span >
                            {USRideBoxCount.count[3].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[3].percentage}
                          
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            pathColor: "#FFBF00",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Reckless Driving
                        <br />
                        <span >
                          {USRideBoxCount.count[3].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Red Zone
                    
                  </CardHeader>
                  <CardBody
                    style={{ padding: "10px" }}
                    className="body_text"
                  >
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          position: "absolute",
                          height: 60,
                          width: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          C5
                        </span>
                        <span >
                          {USRideBoxCount.count[4].percentage}%
                        </span>
                      </span>
                      <div style={{ height: 70, width: 70 }}>
                        <CircularProgressbar
                          value={USRideBoxCount.count[4].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            textSize: "25px",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Quite Dangerous Driving
                        <br />
                        <span >
                          {USRideBoxCount.count[4].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ textAlign: "center" }}
                          >
                            C6
                          </span>
                          <span >
                            {USRideBoxCount.count[5].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={USRideBoxCount.count[5].percentage}
                          
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Dangerous Driving
                        <br />
                        <span >
                          {USRideBoxCount.count[5].totalRides +
                            " / " +
                            USRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}

            {/* <UserRidesKmHrsScoreCBar
              aggregateData={aggregateData}
              totalDays={totalDays}
            /> */}

            <DriverBehaviourRatingForUser receiveSummaryData={handleSummaryData} filter={filters}/>

            <ProgressBarUD
              UDMainDashboardSafetyTableData={UDMainDashboardSafetyTableData}
            />

            <OverAllDrivingScore
              getOverAllDataProps={getOverAllDataProps}
              getSelectedYear={getSelectedYear}
              totalSafeRidePer={totalSafeRidePer}
              rideTotalRide={rideTotalRide}
              lastOverAllDataSY={lastOverAllDataSY}
              USRideBoxCount={dataOverallRideBoxList}
            />
            {/* </Col>
                <Col xl={4} lg={4} md={12} className="mbb">
                  <Card
                    className="sidebar-alert border-0 text-center mb-0 mt-3 pt-3"
                    style={{ height: "220px" }}
                  >
                    <CardBody className="mt-4">
                      <Row className="mt-2">
                        <Col xl={5} lg={5} md={5}>
                          <button
                            style={{
                              backgroundColor: "red",
                              width: "20%",
                              height: "12px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          ></button>
                        </Col>
                        <Col xl={7} lg={7} md={7}>
                          <p style={{ textAlign: "justify", fontSize: "13px" }}>
                            {" "}
                            Indicates HIGH risk
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={5} lg={5} md={5}>
                          <button
                            style={{
                              backgroundColor: "#FFBF00",
                              width: "20%",
                              height: "12px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          ></button>
                        </Col>
                        <Col xl={7} lg={7} md={7}>
                          <p style={{ textAlign: "justify", fontSize: "13px" }}>
                            {" "}
                            Indicates MEDIUM risk
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={5} lg={5} md={5}>
                          <button
                            style={{
                              backgroundColor: "#19543E",
                              width: "20%",
                              height: "12px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          ></button>
                        </Col>
                        <Col xl={7} lg={7} md={7}>
                          <p style={{ textAlign: "justify", fontSize: "13px" }}>
                            {" "}
                            Indicates LOW risk.
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
            {/* </Row> */}
            {/* ) : (
              ""
            )} */}
            {/* <UserDashboardColorIndicators /> */}
          </div>
          <DateTimePickerPopup
            modalOpen={modalIsOpen}
            modalClose={setIsOpen}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setFilter={setFilter}
            filter={filters.durationFilter}
          />
          {/* </div> */}
        </Container>
      </div>
      <ToastContainer style={{ width: "auto" }} />
    </React.Fragment>
  );
};

export default UserDashboard;
