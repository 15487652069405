import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import UtilDateTime from "../../util/UtilDateTime.js";
import { trimValue } from "../../util/UtilFieldValidation.js";
import {
  SC_COMPANY_ID,
  SC_USER_ID,
  SC_DIVISION_ID,
  SC_GROUP_ID,
  SC_LOGIN_USER_ID,
  SC_SELECTED_USER_VALUE,
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

let AdminDashboardController = {
  async getUser(indexStart, indexEnd) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    if (companyId.length > 0 && indexStart.length > 0 && indexEnd.length > 0) {
      var reqPayload = {
        companyId: companyId,
        type: "USER",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: indexStart,
        limit: "10",
      };
      return await ApiService.callGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async AdminSafetyCloudPoint(
    filterDay,
    indexStart,
    pageSize,
    startDateTime,
    endDateTime,
    driverCategory
  ) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashboardFields = { filterType: "", };
    if (filterDay != undefined) {
      dashboardFields = {
        filterType: filterDay,
        driverCategory: driverCategory,
      };
    }

    // var dashboardFields={filterType : fValue}

    if (companyId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: indexStart,
        limit: pageSize,
        dashboardType: "SAD_GROUP_SINGLE_CLIENT_DASHBOARD_SUMMARY",
        dashboardFields: JSON.stringify(dashboardFields),
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async AdminSafetyCloudPoint1(
    filterDay,
    startDateTime,
    endDateTime,
    driverCategory
  ) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashboardFields = { filterType: "" };
    if (filterDay != undefined) {
      dashboardFields = {
        filterType: filterDay,
        driverCategory: driverCategory,
      };
    }

    // var dashboardFields={filterType : fValue}

    if (companyId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: 0,
        limit: 100,
        dashboardType: "SAD_GROUP_SINGLE_CLIENT_DASHBOARD_SUMMARY",
        dashboardFields: JSON.stringify(dashboardFields),
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async getUserController(indexStart) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);

    if (companyId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "USER",
        queryType: "AD_DIVISION_USER_LIST",
        sortOrder: "ASC",
        offset: indexStart,
        limit: "10",
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async AdminUserSafetyCloudPoint(userId, fValue) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);

    var dashboardFields = { filterType: fValue };

    if (companyId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: 0,
        limit: "10000",
        dashboardType: "SAD_GROUP_SINGLE_CLIENT_DASHBOARD_SUMMARY",
        dashboardFields: JSON.stringify(dashboardFields),
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async adminDashboardController( startDateTime, endDateTime, filter, transportType) {
    // alert("startDateTime :" + startDateTime + "endDateTime" + endDateTime + "filter :" + filter )
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var selectedUserValue = UserSessionController.getLocalSessionValue(SC_SELECTED_USER_VALUE) || "";
    
    var dashboardFields = { filterType: filter, transportType:transportType };

    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        dashboardType: "ADMIN_DASHBOARD_TOP_LINE_ITEM_LIST",
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        dashboardFields: JSON.stringify(dashboardFields),
      };
      // console.log("admintopreqParams :" + JSON.stringify(reqParams))

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed","code":"412", "data": []}';
    }
  },
  async superAdminDashboardController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

    if (companyId.length > 0 && divisionId.length > 0 && groupId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        groupId: groupId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "1000",
        dashboardType: "SAD_DASHBOARD_TOP_LINE_ITEM_LIST"
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed","code":"412", "data": []}';
    }
  },

  async userUsageController(startDateTime, endDateTime, uuFilter) {
    // alert("startDateTime :" + startDateTime + "endDateTime" + endDateTime + "uuFilter :" + uuFilter )
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var dashboardFields = { filterType: uuFilter };

    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        dashboardType: "ADMIN_DASHBOARD_TOP_LINE_ITEM_LIST",
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        dashboardFields: JSON.stringify(dashboardFields),
      };
      // console.log("admintopreqParams :" + JSON.stringify(reqParams))

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed","code":"412", "data": []}';
    }
  },

  async adminDashboardGoodScoreController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "5",
        dashboardType: "ADMIN_DASHBOARD_GOOD_DRIVING_SCRORE",
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async adminDashboardPoorScoreController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "5",
        dashboardType: "ADMIN_DASHBOARD_POOR_DRIVING_SCRORE",
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async multiUserDrivingScoreController(
    filterDay,
    offset,
    pageSize,
    startDateTime,
    endDateTime,
    transportType
  ) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashboardFields = { filterType: filterDay, transportType:transportType };

    if (companyId.length > 0 && divisionId.length > 0) {
      // var month1StartDateTime = "",
      //     month1EndDateTime = "";

      // month1StartDateTime = yValue + "-" + mValue + "-01 00:00:00";
      // month1EndDateTime = UtilDateTime.dateManipulation("+",new Date(month1StartDateTime),1,0,0,0,0);
      // month1EndDateTime = UtilDateTime.dateManipulation("-",new Date(month1EndDateTime),0,0,0,0,1);
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: offset,
        limit: pageSize,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        // startDateTime1: "2023-01-01 00:00:00",
        // endDateTime1: "2023-12-31 23:59:59",
        dashboardType: "USER_DASHBOARD_DRIVING_SCORE",
        dashboardFields: JSON.stringify(dashboardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async adminSafetyCategoryController(filter, startDateTime, endDateTime) {
    var dashboardFields = { filterType: filter };

    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    // var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

    if (companyId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        // groupId:groupId,
        type: "SPEEDO_METER_DEVICE",
        dashboardType: "SAD_GROUP_MULTI_CLIENT_DASHBOARD_SUMMARY",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "1000",
        // category:"TESTING",
        dashboardFields: JSON.stringify(dashboardFields),
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

      async monthlyActivities(filter, startDateTime, endDateTime) {
        var dashboardFields={filterType : filter}
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

        if (companyId.length > 0) {
          var reqParams = {
            companyId: companyId,
            divisionId:divisionId,
            groupId:groupId,
            type: "SPEEDO_METER_DEVICE_DATA",
            dashboardType:"AD_SINGLE_CLIENT_MONTHLY_ACTIVITY",
            sortBy: "createdAt",
            sortOrder: "ASC",
            offset: "0",
            limit: "100",
            dashboardFields:JSON.stringify(dashboardFields),
            startDateTime : startDateTime,
            endDateTime : endDateTime
          };
    
          return await ApiService.encryptCallGET(
            apiConfig.API_VERSION_1,
            apiEndPoint.DASHBOARD,
            1,
            reqParams
          );
          
        } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
        
      },

  async activeUserTable(filter, sortOrder, indexStart) {
    var dashboardFields = { filterType: filter };

    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

    if (companyId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        groupId: groupId,
        type: "SPEEDO_METER_DEVICE_DATA",
        dashboardType: "AD_SINGLE_CLIENT_DRIVER_PERFOMANCE_LIST",
        sortBy: "createdAt",
        sortOrder: sortOrder,
        offset: indexStart,
        limit: "10",
        dashboardFields: JSON.stringify(dashboardFields),
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async activeUserList(
    indexStart,
    filter,
    sort,
    subFilterText,
    startDateTime,
    endDateTime,
    deviceFilter,
    pageSize
  ) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

    var dashboardFields = {
      filterType: filter,
      subFilterType: "WILD_CARD_SEARCH",
      subFilterText: subFilterText,
      deviceType: deviceFilter
    };

    if (companyId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        groupId: groupId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: sort,
        offset: indexStart,
        limit: pageSize,
        dashboardType: "AD_SINGLE_CLIENT_PERFOMANCE_USER_LIST",
        dashboardFields: JSON.stringify(dashboardFields),
        startDateTime: startDateTime,
        endDateTime: endDateTime,
  
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async userSummaryList(filter, startDateTime, endDateTime) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

    var dashboardFields = { filterType: filter };

    if (companyId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        groupId: groupId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: 0,
        limit: 1000,
        dashboardType: "AD_SINGLE_CLIENT_LOWEST_PERFOMANCE_USER_LIST",
        dashboardFields: JSON.stringify(dashboardFields),
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async userSummaryListExport(filter, startDateTime, endDateTime) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

    var dashboardFields = { filterType: filter };

    if (companyId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        groupId: groupId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: 0,
        limit: 1000,
        dashboardType: "AD_SINGLE_CLIENT_LOWEST_PERFOMANCE_USER_LIST",
        dashboardFields: JSON.stringify(dashboardFields),
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async activeUserListAppBoot() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    // var dashboardFields={filterType : filter,subFilterType: "WILD_CARD_SEARCH",subFilterText:subFilterText}
    if (companyId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        groupId: groupId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "",
        offset: 0,
        limit: 100,
        dashboardType: "AD_SINGLE_CLIENT_PERFOMANCE_USER_LIST",
        //   dashboardFields:JSON.stringify(dashboardFields)
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

      async activeUserExcelList (indexStart,filter,sort,subFilterText,startDateTime, endDateTime,deviceFilter){
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

        var dashboardFields={filterType : filter,subFilterType: "WILD_CARD_SEARCH",subFilterText:subFilterText,deviceType: deviceFilter}
    
        if (companyId.length > 0) {
    
          var reqPayload = {
              companyId: companyId,
              divisionId:divisionId,
              groupId:groupId,
              type: "SPEEDO_METER_DEVICE",
              sortBy: "createdAt",
              sortOrder: sort,
              offset: indexStart,
              limit: 1000,
              dashboardType: "AD_SINGLE_CLIENT_PERFOMANCE_USER_LIST",
              dashboardFields:JSON.stringify(dashboardFields),
              startDateTime: startDateTime,
              endDateTime: endDateTime,
          };
    
    
          return await ApiService.encryptCallGET(
              apiConfig.API_VERSION_1,
              apiEndPoint.DASHBOARD,
              1,
              reqPayload
          );
      } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
      }
    
      },

      async overallSummary (indexStart,filter,subFilterText, startDateTime, endDateTime, transportType){
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        
        var dashboardFields = {filterType : filter,subFilterType: "WILD_CARD_SEARCH",subFilterText:subFilterText, transportType:transportType}
    
        if (companyId.length > 0) {
    
          var reqPayload = {
              companyId: companyId,
              divisionId:divisionId,
              type: "SPEEDO_METER_DEVICE",
              sortBy: "createdAt",
              sortOrder: "ASC",
              offset: indexStart,
              limit: 1000,
              dashboardType: "AD_SINGLE_CLIENT_CALCULATED_USER_LIST",
              dashboardFields:JSON.stringify(dashboardFields),
              startDateTime: startDateTime, 
              endDateTime: endDateTime,
          };
          return await ApiService.encryptCallGET(
              apiConfig.API_VERSION_1,
              apiEndPoint.DASHBOARD,
              1,
              reqPayload
          );
      } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
      }

      },

      async unsafeDrivers (indexStart,filter,subFilterText, startDateTime, endDateTime){
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        
        var dashboardFields = {filterType : filter,subFilterType: "WILD_CARD_SEARCH",subFilterText:subFilterText}
    
        if (companyId.length > 0) {
    
          var reqPayload = {
              companyId: companyId,
              divisionId:divisionId,
              type: "SPEEDO_METER_DEVICE",
              //sortBy: "createdAt",
              sortOrder: "DESC",
              offset: indexStart,
              limit: 10,
              dashboardType: "AD_SINGLE_CLIENT_CALCULATED_USER_LIST",
              dashboardFields:JSON.stringify(dashboardFields),
              startDateTime: startDateTime, 
              endDateTime: endDateTime,
          };
          return await ApiService.encryptCallGET(
              apiConfig.API_VERSION_1,
              apiEndPoint.DASHBOARD,
              1,
              reqPayload
          );
      } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
      }

      },


      async overallSummaryForUser (indexStart,filter,subFilterText, startDateTime, endDateTime){
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        
        var dashboardFields = {filterType : filter,subFilterType: "WILD_CARD_SEARCH",subFilterText:subFilterText}
    
        if (companyId.length > 0) {
    
          var reqPayload = {
              companyId: companyId,
              divisionId:divisionId,
              type: "SPEEDO_METER_DEVICE",
              sortBy: "createdAt",
              sortOrder: "ASC",
              offset: indexStart,
              limit: 1000,
              dashboardType: "AD_SINGLE_CLIENT_CALCULATED_USER_LIST",
              dashboardFields:JSON.stringify(dashboardFields),
              startDateTime: startDateTime, 
              endDateTime: endDateTime,
              userId: userId,
          };
          return await ApiService.encryptCallGET(
              apiConfig.API_VERSION_1,
              apiEndPoint.DASHBOARD,
              1,
              reqPayload
          );
      } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
      }

      },
      async overallSummaryUBI (indexStart, filter, subFilterText, startDateTime, endDateTime){
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_LOGIN_USER_ID);
        
        var dashboardFields = {filterType : filter,subFilterType: "WILD_CARD_SEARCH",subFilterText:subFilterText}
    
        if (companyId.length > 0) {
    
          var reqPayload = {
              companyId: companyId,
              divisionId:divisionId,
              userId: userId,
              type: "SPEEDO_METER_DEVICE",
              sortBy: "createdAt",
              sortOrder: "ASC",
              offset: indexStart,
              limit: 1000,
              dashboardType: "AD_SINGLE_CLIENT_ALL_USER_OVERALL_RIDE_SUMMARY",
              dashboardFields:JSON.stringify(dashboardFields),
              startDateTime: startDateTime, 
              endDateTime: endDateTime,
          };
          return await ApiService.encryptCallGET(
              apiConfig.API_VERSION_1,
              apiEndPoint.DASHBOARD,
              1,
              reqPayload
          );
      } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
      }
    
      },
      async overallSummaryExport (filter,startDateTime,endDateTime, transportType){
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        
        var dashboardFields = {filterType : filter,subFilterType: "WILD_CARD_SEARCH", transportType:transportType}
    
        if (companyId.length > 0) {
    
          var reqPayload = {
              companyId: companyId,
              divisionId:divisionId,
              type: "SPEEDO_METER_DEVICE",
              sortBy: "createdAt",
              sortOrder: "ASC",
              offset: 0,
              limit: 10000,
              dashboardType: "AD_SINGLE_CLIENT_ALL_USER_OVERALL_RIDE_SUMMARY",
              dashboardFields:JSON.stringify(dashboardFields),
              startDateTime: startDateTime, 
              endDateTime: endDateTime,
          };
          return await ApiService.encryptCallGET(
              apiConfig.API_VERSION_1,
              apiEndPoint.DASHBOARD,
              1,
              reqPayload
          );
      } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
      }
    
      },
      async overallSummaryExportForUser (filter,startDateTime,endDateTime){
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        
        var dashboardFields = {filterType : filter,subFilterType: "WILD_CARD_SEARCH"}
    
        if (companyId.length > 0) {
    
          var reqPayload = {
              companyId: companyId,
              divisionId:divisionId,
              userId: userId,
              type: "SPEEDO_METER_DEVICE",
              sortBy: "createdAt",
              sortOrder: "ASC",
              offset: 0,
              limit: 10000,
              dashboardType: "AD_SINGLE_CLIENT_ALL_USER_OVERALL_RIDE_SUMMARY",
              dashboardFields:JSON.stringify(dashboardFields),
              startDateTime: startDateTime, 
              endDateTime: endDateTime,
          };
          return await ApiService.encryptCallGET(
              apiConfig.API_VERSION_1,
              apiEndPoint.DASHBOARD,
              1,
              reqPayload
          );
      } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
      }
    
      },

};

export default AdminDashboardController;
