import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Table, CardHeader } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { PiDownloadSimple } from "react-icons/pi";
import jsPDF from "jspdf";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController.js";
import "../OverallSummary/overallSummaryStyle.css";
import Loader from "../../../components/Common/loader";
import {
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_ID,
  SC_USER_USERNAME,
} from "../../../constant/StorageConstant.js";
import { Link } from "react-router-dom";
import { displayText } from "../../../constant/MessageConstant.js";
import ToastService from "../../../components/Common/toastService.js";
import ValueFormat from "../../../util/ValueFormat.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import i1 from "../../../assets/images/adminDashboard/ad-dr-name.svg"
import i2 from "../../../assets/images/adminDashboard/ad-dr-uname.svg"
import userFirstNameIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/userFirstNameIcon.svg"
import i3 from "../../../assets/images/adminDashboard/ad-dr-rank.svg"
import i4 from "../../../assets/images/adminDashboard/totalRides.svg"
import i5 from "../../../assets/images/adminDashboard/ad-dr-riskAlerts.svg"
import i6 from "../../../assets/images/adminDashboard/ad-dr-otherAlerts.svg"
import i7 from "../../../assets/images/adminDashboard/ad-dr-overspeed.svg"
import i8 from "../../../assets/images/adminDashboard/ad-dr-mobileCall.svg"
import i9 from "../../../assets/images/adminDashboard/ad-dr-mobileScreen.svg"
import i10 from "../../../assets/images/adminDashboard/ad-dr-acceleration.svg"
import i11 from "../../../assets/images/adminDashboard/ad-dr-Braking.svg"
import i12 from "../../../assets/images/adminDashboard/ad-dr-cornering.svg"
import i13 from "../../../assets/images/adminDashboard/ad-dr-score.svg"
import crash from "../../../assets/images/adminDashboard/crash_accident.svg"
import UBIDrivingScore from "../../../assets/images/Ubi Images/UBIDrivingScore.svg"
import UBIAnticipation from "../../../assets/images/Ubi Images/UBIAnticipation.svg"
import UBIAnticipationNew from "../../../assets/images/adminDashboard/myRidesPageIcons/AnticipationIcon.svg";
import UBISelfConfidence from "../../../assets/images/adminDashboard/myRidesPageIcons/SelfConfidenceIcon.svg"
import UBIDrivingSkill from "../../../assets/images/adminDashboard/myRidesPageIcons/DrivingSkillIcon.svg"
import UBIDrivingStyle from "../../../assets/images/adminDashboard/myRidesPageIcons/DrivingStyleIcon.svg"
import UBIDrivingState from "../../../assets/images/adminDashboard/myRidesPageIcons/DrivingStateIcon.svg"
import UBIMobileScreenUse from "../../../assets/images/Ubi Images/UBIMobileScreenUse.svg"
import UBIMobileCallUse from "../../../assets/images/adminDashboard/myRidesPageIcons/MobileUsageIcon.svg"
import UBIKmsDriven from "../../../assets/images/Ubi Images/UBIKmsDriven.svg"
import UBIStressStrain from "../../../assets/images/adminDashboard/myRidesPageIcons/AccBrakingCorIcon.svg"
import UBIScoreImage from "../../../assets/images/Ubi Images/newDrivingEfficiencyImage.svg"

import tdistance from "../../../assets/images/adminDashboard/ad-2r-distance.svg"
import tduration from "../../../assets/images/adminDashboard/ad-2r-time.svg"

import infoGif from "../../../assets/images/gif/info-unscreen.gif"

//New

import locationIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIcon.svg";
import minsIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/myRidesMins.svg";
import OverSpeedIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIconNew.svg";
import AccelerationIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/AccelerationIcon.svg";
import BreakingIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/BrakingIcon.svg";
import CorneringIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/CorneringIcon.svg";
import ScreenCountIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/ScreenCountIcon.svg";
import CallCountIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/CallCountIcon.svg";
import CountImage from "../../../assets/images/adminDashboard/myRidesPageIcons/CountImage.svg";
import CallCountIconNewOne from "../../../assets/images/adminDashboard/myRidesPageIcons/CallCountIconNew.svg";
import steeringWheel from "../../../assets/images/adminDashboard/steering-wheel.svg";
import UserNameIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";

export default function OverallSummary({ receiveSummaryData, filter, selectedUserValue }) {

  const [indexStart, setIndexStart] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [summaryDataList, setSummaryDataList] = useState([]);
  const [dataLength, setDataLength] = useState(null);
  const [subFilterText, setSubFilterText] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [dataDisplay, setDataDisplay] = useState(false);

  const exportPDF = async () => {
    setShowLoader(true);
    y
    var result = await AdminDashboardController.overallSummaryExport(
      filter.durationFilter,
      filter.startDateTime,
      filter.endDateTime,
      transportType
    );
    setShowLoader(true);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      // console.log("getOverAllSummaryExcel: ", resultJSONData);
      setDataLength(resultJSONData.length);
      var summaryData = [];
      resultJSONData.map((value, i) => {
        var rank = (pageNumber - 1) * 1000 + (1 + i);
        var firstName = ValueFormat.parseAsString((value.firstName).toUpperCase());
        var username = ValueFormat.parseAsString(value.username);
        var totalRides = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.totalRides));
        var overallDrivingScore = ValueFormat.parseAsFloat(value.drivingScorePoint, 1) || "-";
        var overallAnticipation = ValueFormat.parseAsFloat(value.anticipationPoint, 1) || "-";
        var overallSelfConfidence = ValueFormat.parseAsFloat(value.selfConfidencePoint, 1) || "-";
        var overallDrivingSkill = ValueFormat.parseAsFloat(value.drivingSkillPoint, 1) || "-";
        var dStyleValue = ValueFormat.parseAsFloat(value.drivingStylePoint, 1) || "-";
        var dStateValue = ValueFormat.parseAsFloat(value.drivingStatePoint, 1) || "-";
        var kmsDriven = ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1) || "-";
        var mobileCallValue = ValueFormat.parseAsFloat(value.mobileCallPoint, 1) || "-";
        var mobileScreenValue = ValueFormat.parseAsFloat(value.mobileScreenPoint, 1) || "-";
        var abcPoint = ValueFormat.parseAsFloat(value.accBrakCorPoint, 1) || "-";

        var totalEfficiencyRaw =
          parseFloat(ValueFormat.parseAsFloat(value.drivingScorePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.anticipationPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.selfConfidencePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.drivingSkillPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.drivingStylePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.drivingStatePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.mobileCallPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.mobileScreenPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.accBrakCorPoint, 1));

          var totalEfficiency = ValueFormat.parseAsFloat(totalEfficiencyRaw, 1);

        var ratings = "";
        if (totalEfficiency >= 0 && totalEfficiency <= 80) {
          ratings = "Poor";
        } else if (totalEfficiency >= 81 && totalEfficiency <= 93) {
          ratings = "Fair";
        } else {
          ratings = "Good"
        }

        var resultObject = {
          ratings: ratings,
          firstName: firstName,
          username: username,
          drivingScore: overallDrivingScore,
          totalRides: totalRides,
          ABCPoint: abcPoint,
          mobileScreenPointNew: mobileScreenValue,
          mobileUsePointNew: mobileCallValue,
          overallAnticipation: overallAnticipation,
          overallSelfConfidence: overallSelfConfidence,
          overallDrivingSkill: overallDrivingSkill,
          totalKmSPoint: kmsDriven,
          DSP: dStateValue,
          // overSpeedPoint: overSpeedPoint,
          DSTP: dStyleValue,
          // efficiency: efficiency
          efficiency: totalEfficiency
        };
        summaryData.push(resultObject);
        setDataDisplay(
          Object.values(resultObject).some((value) => value !== 0)
        );
      });

      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(10);

      const title = "Driving Behaviour Rating | Motiv AI";
      const headers = [
        [
          "Rating",
          "Driving\nBehaviour\nScore",
          "Name",
          "Username",
          "Total\nRides",
          "Driving\nPoints",
          "Anticipation\nPoints",
          "Self\nConfidence\n Points",
          "Driving\nSkill Points",
          "Driving\nStyle Points",
          "Driver\nState\nPoints",
          // "Overspeed\nPoints",
          "Mobile Screen\nUsage Points",
          "Mobile Call\nUsage Points",
          "Overspeed\nKMS Driven\nPoints",
          "Acc / Brak\n/ Cor Points"
        ],
      ];

      let content;

      if (resultJSONData.length === 0 || dataDisplay != true) {
        const text = "Data not available";
        const textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const textHeight =
          doc.internal.getLineHeight() / doc.internal.scaleFactor;
        const startX = (doc.internal.pageSize.width - textWidth) / 2;
        const startY = (doc.internal.pageSize.height - textHeight) / 2;
        content = {
          startY: 50,
          head: headers,
          body: [],
        };
        doc.text(text, startX, startY);
      } else {
        const fontSize = 10;
        const Data = summaryData.map((elt) => [
          elt.ratings,
          elt.efficiency,
          elt.firstName,
          elt.username,
          elt.totalRides,
          elt.drivingScore,
          elt.overallAnticipation,
          elt.overallSelfConfidence,
          elt.overallDrivingSkill,
          elt.DSTP,
          elt.DSP,
          // elt.overSpeedPoint,
          elt.mobileScreenPointNew,
          elt.mobileUsePointNew,
          elt.totalKmSPoint,
          elt.ABCPoint,
        ]);

        content = {
          startY: 50,
          head: headers,
          body: Data,
          didDrawPage: (hookData) => {
            doc.setFontSize(fontSize);
          },
        };
      }

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("Driving Behaviour Rating");
      setShowLoader(false)();
    } else {
      setShowLoader(false);
      ToastService.errormsg(displayText.ER_1206);
    }
  };

  const exportPDFViolation = async () => {
    setShowLoader(true);
    const transportType = selectedUserValue;
    var result = await AdminDashboardController.overallSummaryExport(
      filter.durationFilter,
      filter.startDateTime,
      filter.endDateTime,
      transportType
    );
    setShowLoader(true);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      // console.log("getOverAllSummaryExcel: ", resultJSONData);
      setDataLength(resultJSONData.length);
      var summaryData = [];
      resultJSONData.map((value, i) => {
        var firstName = ValueFormat.parseAsString((value.firstName).toUpperCase());
        var username = ValueFormat.parseAsString(value.username);
        var totalRides = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.totalRides));

        var overallDrivingScore = ValueFormat.parseAsFloat(value.drivingScorePoint, 1) || "-";
        var overallAnticipation = ValueFormat.parseAsFloat(value.anticipationPoint, 1) || "-";
        var overallSelfConfidence = ValueFormat.parseAsFloat(value.selfConfidencePoint, 1) || "-";
        var overallDrivingSkill = ValueFormat.parseAsFloat(value.drivingSkillPoint, 1) || "-";
        var dStyleValue = ValueFormat.parseAsFloat(value.drivingStylePoint, 1) || "-";
        var dStateValue = ValueFormat.parseAsFloat(value.drivingStatePoint, 1) || "-";
        var kmsDriven = ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1) || "-";
        var mobileCallValue = ValueFormat.parseAsFloat(value.mobileCallPoint, 1) || "-";
        var mobileScreenValue = ValueFormat.parseAsFloat(value.mobileScreenPoint, 1) || "-";
        var abcPoint = ValueFormat.parseAsFloat(value.accBrakCorPoint, 1) || "-";

        var totalSevereAccelerationLowCount = ValueFormat.parseAsFloat(value.totalSevereAccelerationLowCount, 2) || "-";
        var totalSevereAccelerationMediumCount = ValueFormat.parseAsFloat(value.totalSevereAccelerationMediumCount, 2) || "-";
        var totalSevereAccelerationHighCount = ValueFormat.parseAsFloat(value.totalSevereAccelerationHighCount, 2) || "-";

        var totalAccelerationCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationMediumCount, 0))
          + parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationHighCount, 0));

        var totalSevereBrakingLowCount = ValueFormat.parseAsFloat(value.totalSevereBrakingLowCount, 2) || "-";
        var totalSevereBrakingMediumCount = ValueFormat.parseAsFloat(value.totalSevereBrakingMediumCount, 2) || "-";
        var totalSevereBrakingHighCount = ValueFormat.parseAsFloat(value.totalSevereBrakingHighCount, 2) || "-";

        var totalBrakingCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingMediumCount, 0))
          + parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingHighCount, 0));

        var totalSevereCorneringLowCount = ValueFormat.parseAsFloat(value.totalSevereCorneringLowCount, 2) || "-";
        var totalSevereCorneringMediumCount = ValueFormat.parseAsFloat(value.totalSevereCorneringMediumCount, 2) || "-";
        var totalSevereCorneringHighCount = ValueFormat.parseAsFloat(value.totalSevereCorneringHighCount, 2) || "-";

        var totalCorneringCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringMediumCount, 0))
          + parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringHighCount, 0));

        var totalMobileUseInAcceptedCount = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedCount, 0) || "-";
        var totalMobileUseInAcceptedDistance = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDistance, 0) || "-";
        var totalMobileUseInAcceptedDuration = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDuration, 0) || "-";

        var totalMobileScreenScreenOnCount = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnCount, 0) || "-";
        var totalMobileScreenScreenOnDistance = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDistance, 0) || "-";
        var totalMobileScreenScreenOnDuration = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDuration, 0) || "-";

        var totalOverSpeedCount = ValueFormat.parseAsFloat(value.totalOverSpeedCount, 0) || "-";
        var totalMediumRiskCount = ValueFormat.parseAsFloat(value.totalMediumRiskCount, 0) || "-";
        var totalHighRiskCount = ValueFormat.parseAsFloat(value.totalHighRiskCount, 0) || "-";
        var totalOverSpeedDuration = ValueFormat.parseAsFloat(value.totalOverSpeedDuration, 2) || "-";

        var tKiloMeter = ValueFormat.parseAsFloat(value.tKiloMeter, 2) || "-";
        var tTravelTime = ValueFormat.parseAsFloat(value.tTravelTime, 0) || "-";
        var crashCount = ValueFormat.parseAsString(value.crashCount) || "-";

        var totalEfficiencyRaw =
          parseFloat(ValueFormat.parseAsFloat(value.drivingScorePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.anticipationPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.selfConfidencePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.drivingSkillPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.drivingStylePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.drivingStatePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.mobileCallPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.mobileScreenPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.accBrakCorPoint, 1));

          var totalEfficiency = ValueFormat.parseAsFloat(totalEfficiencyRaw, 1);

        var ratings = "";
        if (totalEfficiency >= 0 && totalEfficiency <= 80) {
          ratings = "Poor";
        } else if (totalEfficiency >= 81 && totalEfficiency <= 93) {
          ratings = "Fair";
        } else {
          ratings = "Good";
        }

        var resultObject = {
          ratings: ratings,
          firstName: firstName,
          username: username,
          drivingScore: overallDrivingScore,
          totalRides: totalRides,
          ABCPoint: abcPoint,
          mobileScreenPointNew: mobileScreenValue,
          mobileUsePointNew: mobileCallValue,
          overallAnticipation: overallAnticipation,
          overallSelfConfidence: overallSelfConfidence,
          overallDrivingSkill: overallDrivingSkill,
          totalKmSPoint: kmsDriven,
          DSP: dStateValue,
          DSTP: dStyleValue,
          // efficiency: efficiency
          efficiency: totalEfficiency,
          crashCount: crashCount,
          totalAccelerationCount: totalAccelerationCount,
          totalBrakingCount: totalBrakingCount,
          totalCorneringCount: totalCorneringCount,

          totalMobileUseInAcceptedCount: totalMobileUseInAcceptedCount,
          totalMobileUseInAcceptedDistance: totalMobileUseInAcceptedDistance,
          totalMobileUseInAcceptedDuration: totalMobileUseInAcceptedDuration,

          totalMobileScreenScreenOnCount: totalMobileScreenScreenOnCount,
          totalMobileScreenScreenOnDistance: totalMobileScreenScreenOnDistance,
          totalMobileScreenScreenOnDuration: totalMobileScreenScreenOnDuration,

          totalOverSpeedCount: totalOverSpeedCount,
          totalMediumRiskCount: totalMediumRiskCount,
          totalHighRiskCount: totalHighRiskCount,
          totalOverSpeedDuration: totalOverSpeedDuration,

          tKiloMeter: tKiloMeter,
          tTravelTime: tTravelTime
        };
        summaryData.push(resultObject);
        setDataDisplay(
          Object.values(resultObject).some((value) => value !== 0)
        );
      });

      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(10);

      const title = "Driving Behaviour Rating | Motiv AI";
      const headers = [
        [
          "Rating",
          "Driving Behaviour Score",
          "Name",
          "Username",
          "Rides",
          "Total Distance (kms)",
          "Total Duration (Mins)",
          "Crash (Count)",
          "High Risk (Count)",
          "Medium Risk (Count)",
          "Overspeed (Mins | Count)",
          "Mobile Screen (Mins | Count)",
          "Mobile Call (Mins | Count)",
          "Acceleration (Count)",
          "Braking (Count)",
          "Cornering (Count)"
        ],
      ];

      let content;

      if (resultJSONData.length === 0 || dataDisplay != true) {
        const text = "Data not available";
        const textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const textHeight =
          doc.internal.getLineHeight() / doc.internal.scaleFactor;
        const startX = (doc.internal.pageSize.width - textWidth) / 2;
        const startY = (doc.internal.pageSize.height - textHeight) / 2;
        content = {
          startY: 50,
          head: headers,
          body: [],
        };
        doc.text(text, startX, startY);
      } else {
        const fontSize = 10;
        const Data = summaryData.map((elt) => [
          elt.ratings,
          elt.efficiency,
          elt.firstName,
          elt.username,
          elt.totalRides,
          elt.tKiloMeter,
          elt.tTravelTime,
          elt.crashCount,
          elt.totalHighRiskCount,
          elt.totalMediumRiskCount,
          `${elt.totalOverSpeedDuration} | ${elt.totalOverSpeedCount}`,
          `${elt.totalMobileScreenScreenOnDuration} | ${elt.totalMobileScreenScreenOnCount}`,
          `${elt.totalMobileUseInAcceptedDuration} | ${elt.totalMobileUseInAcceptedCount}`,
          elt.totalAccelerationCount,
          elt.totalBrakingCount,
          elt.totalCorneringCount,
        ]);

        content = {
          startY: 50,
          head: headers,
          body: Data,
          didDrawPage: (hookData) => {
            doc.setFontSize(fontSize);
          },
        };
      }

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("Dashboard For Violation");
      setShowLoader(false)();
    } else {
      setShowLoader(false);
      ToastService.errormsg(displayText.ER_1206);
    }
  };
  const getOverAllSummary = async () => {
    setDataDisplay(null);
    const transportType = selectedUserValue;
    var result = await AdminDashboardController.overallSummary(
      indexStart,
      filter.durationFilter,
      subFilterText,
      filter.startDateTime,
      filter.endDateTime,
      transportType
    );
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      if (resultJSONData.length > 0) {
        setDataDisplay(null);
        // console.log("getOverAllSummary: ", resultJSONData);
        setDataLength(resultJSONData.length);
        var summaryData = [];
        resultJSONData.map((value, i) => {
          var rank = (pageNumber - 1) * 1000 + (1 + i);
          var firstName = ValueFormat.parseAsString((value.firstName).toUpperCase());
          var username = ValueFormat.parseAsString(value.username) || "-";
          var totalRides = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.totalRides)) || "-";
          var userId = ValueFormat.parseAsString(value.userId);
          var overallDrivingScore = ValueFormat.parseAsFloat(value.drivingScorePoint, 0) || "-";
          var overallAnticipation = ValueFormat.parseAsFloat(value.anticipationPoint, 0) || "-";
          var overallSelfConfidence = ValueFormat.parseAsFloat(value.selfConfidencePoint, 0) || "-";
          var overallDrivingSkill = ValueFormat.parseAsFloat(value.drivingSkillPoint, 0) || "-";
          var dStyleValue = ValueFormat.parseAsFloat(value.drivingStylePoint, 0) || "-";
          var dStateValue = ValueFormat.parseAsFloat(value.drivingStatePoint, 0) || "-";
          var overSpeedPoint = ValueFormat.parseAsFloat(value.overSpeedPoint, 2) || "-";
          var kmsDriven = ValueFormat.parseAsFloat(value.kmsDrivenPoint, 0) || "-";
          var mobileCallValue = ValueFormat.parseAsFloat(value.mobileCallPoint, 2) || "-";
          var mobileScreenValue = ValueFormat.parseAsFloat(value.mobileScreenPoint, 2) || "-";
          var abcPoint = ValueFormat.parseAsFloat(value.accBrakCorPoint, 0) || "-";
          var calculatedDrivingScore = ValueFormat.parseAsFloat(value.calculatedDrivingScore, 0) || "-";
          var calculatedDrivingScoreTool = ValueFormat.parseAsFloat(value.calculatedDrivingScore, 2) || "-";
          var drivingBehaviourScore = ValueFormat.parseAsFloat(value.drivingBehaviourScore) || "-";

          var totalEfficiencyRaw =
            parseFloat(ValueFormat.parseAsFloat(value.drivingScorePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.anticipationPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.selfConfidencePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.drivingSkillPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.drivingStylePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.drivingStatePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.mobileCallPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.mobileScreenPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.accBrakCorPoint, 1));

          var totalEfficiency = ValueFormat.parseAsFloat(totalEfficiencyRaw, 0);
          var totalEfficiencyTV = ValueFormat.parseAsFloat(totalEfficiencyRaw, 2);

          var overallDrivingScoreTooltipValue = ValueFormat.parseAsFloat(value.drivingScorePoint, 1) || "-";
          var overallAnticipationTooltipValue = ValueFormat.parseAsFloat(value.anticipationPoint, 1) || "-";
          var overallSelfConfidenceTooltipValue = ValueFormat.parseAsFloat(value.selfConfidencePoint, 1) || "-";
          var overallDrivingSkillTooltipValue = ValueFormat.parseAsFloat(value.drivingSkillPoint, 1) || "-";
          var dStyleValueTooltipValue = ValueFormat.parseAsFloat(value.drivingStylePoint, 1) || "-";
          var dStateValueTooltipValue = ValueFormat.parseAsFloat(value.drivingStatePoint, 1) || "-";
          var kmsDrivenTooltipValue = ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1) || "-";
          var mobileCallValueTooltipValue = ValueFormat.parseAsFloat(value.mobileCallPoint, 1) || "-";
          var mobileScreenValueTooltipValue = ValueFormat.parseAsFloat(value.mobileScreenPoint, 1) || "-";
          var abcPointTooltipValue = ValueFormat.parseAsFloat(value.accBrakCorPoint, 1) || "-";

          var efficiency = ValueFormat.parseAsFloat(value.drivingEfficiency, 0) || "-";
          var efficiencyTooltipValue = ValueFormat.parseAsFloat(value.drivingEfficiency, 2) || "-";

          var totalSevereAccelerationLowCount = ValueFormat.parseAsFloat(value.totalSevereAccelerationLowCount, 2) || "-";
          var totalSevereAccelerationMediumCount = ValueFormat.parseAsFloat(value.totalSevereAccelerationMediumCount, 2) || "-";
          var totalSevereAccelerationHighCount = ValueFormat.parseAsFloat(value.totalSevereAccelerationHighCount, 2) || "-";

          var totalAccelerationCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationMediumCount, 0))
            + parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationHighCount, 0));

          var totalSevereBrakingLowCount = ValueFormat.parseAsFloat(value.totalSevereBrakingLowCount, 2) || "-";
          var totalSevereBrakingMediumCount = ValueFormat.parseAsFloat(value.totalSevereBrakingMediumCount, 2) || "-";
          var totalSevereBrakingHighCount = ValueFormat.parseAsFloat(value.totalSevereBrakingHighCount, 2) || "-";

          var totalBrakingCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingMediumCount, 0))
            + parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingHighCount, 0));

          var totalSevereCorneringLowCount = ValueFormat.parseAsFloat(value.totalSevereCorneringLowCount, 2) || "-";
          var totalSevereCorneringMediumCount = ValueFormat.parseAsFloat(value.totalSevereCorneringMediumCount, 2) || "-";
          var totalSevereCorneringHighCount = ValueFormat.parseAsFloat(value.totalSevereCorneringHighCount, 2) || "-";

          var totalCorneringCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringMediumCount, 0))
            + parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringHighCount, 0));

          var totalMobileUseInAcceptedCount = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedCount, 0) || "-";
          var totalMobileUseInAcceptedDistance = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDistance, 0) || "-";
          const parsedNum = parseFloat(value.totalMobileUseInAcceptedDuration);

          var totalMobileUseInAcceptedDuration = Number.isInteger(parsedNum) ? parsedNum :  ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDuration, 2) || "-";

          var totalMobileScreenScreenOnCount = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnCount, 0) || "-";
          var totalMobileScreenScreenOnDistance = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDistance, 0) || "-";
          const parsedNumMobileScreen = parseFloat(value.totalMobileScreenScreenOnDuration);
          var totalMobileScreenScreenOnDuration = Number.isInteger(parsedNumMobileScreen) ? parsedNumMobileScreen : ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDuration, 2) || "-";

          var totalOverSpeedCount = ValueFormat.parseAsFloat(value.totalOverSpeedCount, 0) || "-";
          const parsedNumOverSpeed = parseFloat(value.totalOverSpeedDuration);
          var totalOverSpeedDuration =  Number.isInteger(parsedNumOverSpeed) ? parsedNumOverSpeed : ValueFormat.parseAsFloat(value.totalOverSpeedDuration, 2) || "-";
          var totalMediumRiskCount = ValueFormat.parseAsFloat(value.totalMediumRiskCount, 0) || "-";
          var totalHighRiskCount = ValueFormat.parseAsFloat(value.totalHighRiskCount, 0) || "-";

          var tKiloMeter = ValueFormat.parseAsFloat(value.tKiloMeter, 2) || "-";
          var tTravelTime = ValueFormat.parseAsFloat(value.tTravelTime, 2) || "-";

          var crashCount = ValueFormat.parseAsString(value.crashCount) || "-";

          var tKiloMeterTV = ValueFormat.parseAsFloat(value.tKiloMeter, 2) || "-";
          var tTravelTimeTV = ValueFormat.parseAsFloat(value.tTravelTime, 2) || "-";
          var totalOverSpeedDurationTV = ValueFormat.parseAsFloat(value.totalOverSpeedDuration, 2) || "-";
          var totalMobileScreenScreenOnDurationTV = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDuration, 2) || "-";
          var totalMobileUseInAcceptedDurationTV = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDuration, 2) || "-";
          var totalMobileUseInAcceptedDurationTVCount = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDuration, 0) || "-";

          var mobileUsage =
          parseFloat(value.mobileCallPoint) +
          parseFloat(value.mobileScreenPoint);
          var mobileUsageTotal = ValueFormat.parseAsFloat(mobileUsage, 0) || 0
          var resultObject = {
            rank: rank,
            firstName: firstName,
            username: username || "N/A",
            drivingScore: overallDrivingScore,
            calculatedDrivingScore:calculatedDrivingScore,
            calculatedDrivingScoreTool:calculatedDrivingScoreTool,
            drivingBehaviourScore:drivingBehaviourScore,
            totalRides: totalRides,
            userId: userId,
            ABCPoint: abcPoint,
            mobileScreenPointNew: mobileScreenValue,
            mobileUsePointNew: mobileCallValue,
            overallAnticipation: overallAnticipation,
            overallSelfConfidence: overallSelfConfidence,
            overallDrivingSkill: overallDrivingSkill,
            totalKmSPoint: kmsDriven,
            DSP: dStateValue,
            DSTP: dStyleValue,
            overSpeedPoint: overSpeedPoint,
            efficiency: efficiency,
            totalEfficiency: totalEfficiency,
            efficiencyTooltipValue: efficiencyTooltipValue,
            crashCount: ValueFormat.parseAsFloat(crashCount),

            overallDrivingScoreTooltipValue: overallDrivingScoreTooltipValue,
            overallAnticipationTooltipValue: overallAnticipationTooltipValue,
            overallSelfConfidenceTooltipValue: overallSelfConfidenceTooltipValue,
            overallDrivingSkillTooltipValue: overallDrivingSkillTooltipValue,
            dStyleValueTooltipValue: dStyleValueTooltipValue,
            dStateValueTooltipValue: dStateValueTooltipValue,
            kmsDrivenTooltipValue: kmsDrivenTooltipValue,
            mobileCallValueTooltipValue: mobileCallValueTooltipValue,
            mobileScreenValueTooltipValue: mobileScreenValueTooltipValue,
            abcPointTooltipValue: abcPointTooltipValue,
            totalEfficiencyTV: totalEfficiencyTV,

            totalAccelerationCount: totalAccelerationCount,
            totalBrakingCount: totalBrakingCount,
            totalCorneringCount: totalCorneringCount,
            totalOverSpeedDuration: totalOverSpeedDuration,
            totalMobileUseInAcceptedCount: totalMobileUseInAcceptedCount,
            totalMobileUseInAcceptedDistance: totalMobileUseInAcceptedDistance,
            totalMobileUseInAcceptedDuration: totalMobileUseInAcceptedDuration,
            totalMobileUseInAcceptedDurationTVCount:totalMobileUseInAcceptedDurationTVCount,

            totalMobileScreenScreenOnCount: totalMobileScreenScreenOnCount,
            totalMobileScreenScreenOnDistance: totalMobileScreenScreenOnDistance,
            totalMobileScreenScreenOnDuration: totalMobileScreenScreenOnDuration,

            totalOverSpeedCount: totalOverSpeedCount,
            totalMediumRiskCount: totalMediumRiskCount,
            totalHighRiskCount: totalHighRiskCount, 

            tKiloMeter: tKiloMeter,
            tTravelTime: tTravelTime,

            totalOverSpeedDurationTV: totalOverSpeedDurationTV,
            totalMobileScreenScreenOnDurationTV: totalMobileScreenScreenOnDurationTV,
            totalMobileUseInAcceptedDurationTV: totalMobileUseInAcceptedDurationTV,
            tKiloMeterTV: tKiloMeterTV,
            tTravelTimeTV: tTravelTimeTV,
            mobileUsage: mobileUsageTotal
          };
          summaryData.push(resultObject);
          // console.log("resultObject: ", resultObject);
          setDataDisplay(
            Object.values(resultObject).some((value) => value !== 0)
          );
        });
      } else {
        setDataDisplay(false);
      }
      receiveSummaryData({ data: summaryData, filter: filter.durationFilter });

      setSummaryDataList(summaryData);
    } else if (status == "FAILED") {
      setDataDisplay(false);

      var code = resultJSON.error.code;
      if (code == "E1206") {
        setDataDisplay("failed");
      }
    }
  };

  useEffect(() => {
    getOverAllSummary();
  }, [filter.durationFilter, filter.startDateTime, filter.endDateTime, selectedUserValue]);

  const TableStyle = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      border: "0.2px",
      margin: "0px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: "#fff",
      height: "20px",
      fontWeight: 600,
      fontSize: "12px",
    },
  };

  return (
    <div>
      {showLoader && <Loader />}

      {/* <Row>
        <Col xs={12}>
          <Card>
            <CardHeader className="cardHeader" style={{ paddingLeft: '12px' }}>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <h5 className="sub_heading m-0">Driving Behaviour Rating</h5>
                 
                  <ReactTooltip
                    id={"info"}
                    place="top"
                    
                    multiline={true}
                    style={{ backgroundColor: "black", height: 'auto', width: '40vw', display: 'flex', alignItems: 'center', fontSize: '14px', padding: '20px' }}
                 
                  />


                </div>
               

                <div className="ms-auto">
                  <div style={{ verticalAlign: "middle", display: "flex" }}>

                    <div>
                      <div className="d-flex mt-1 align-items-center" >
                        <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                          <button className="btn me-1" style={{ backgroundColor: '#E21212' }} />
                          Poor (0-80)
                        </div>
                        <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                          <button className="btn me-1" style={{ backgroundColor: '#FFBF00' }} />
                          Fair (80-93)
                        </div>
                        <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                          <button className="btn me-1" style={{ backgroundColor: '#19543E' }} />
                          Good (93-100)
                        </div>
                      </div>
                    </div>

                   

                  </div>
                </div>
              </div>
            </CardHeader>
            {dataDisplay === true ? (
              <CardBody className="p-0">
                <div
                  
                  style={{ height: 480, overflowY: "auto" }}
                >
                  <div style={{ height: 'auto', display: 'flex', alignItems: 'center', fontSize: '14px', padding: '10px' }}>
          
                    </div>

                  <Table bordered style={TableStyle.table}>

                    <thead>
                    <tr>
                                                        
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Rating</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Driving<br />Score</th> 
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Name <br /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Username <br /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Total<br />Rides</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="4">Driving Risk Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="7">Driving Behavior Data</th>
                     </tr>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br /><br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br /><br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br /><br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br /><br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Risk<br /><img src={UBIDrivingScore} alt="UBIDrivingScore" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br />Anticipation <br /><img src={UBIAnticipation} alt="UBIAnticipation" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Self<br />Confidence<br /><img src={UBISelfConfidence} alt="UBISelfConfidence" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Skill<br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving<br />Behaviour<br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Style<br /><img src={UBIDrivingStyle} alt="UBIDrivingStyle" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driver <br />State<br /><img src={UBIDrivingState} alt="UBIDrivingState" style={{ marginTop: "5px", height: "20px" }} /></th>
                        
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen  <br />Usage<br /><img src={UBIMobileScreenUse} alt="UBIMobileScreenUse" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call <br />Usage<br /><img src={UBIMobileCallUse} alt="UBIMobileCallUse" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed KMS<br /> Driven<br /><img src={UBIKmsDriven} alt="UBIKmsDriven" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br />Acc/Brak/Cor<br /><img src={UBIStressStrain} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                      </tr>
                    </thead>

                    <tbody>
                      {(summaryDataList || []).map((apiData, index) => (
                        <tr key={index}>
                           <td style={{ textAlign: "center", verticalAlign: "middle" }}>{apiData.calculatedDrivingScore >= 0 && apiData.calculatedDrivingScore <= 80 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Poor</p><button className="btn" style={{ backgroundColor: '#E21212' }} /></div> :
                           apiData.calculatedDrivingScore >= 81 && apiData.calculatedDrivingScore <= 93 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Fair</p><button className="btn" style={{ backgroundColor: '#FFBF00' }} /></div> :
                              <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Good</p><button className="btn" style={{ backgroundColor: '#19543E' }} /></div>}
                          </td>

                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }} data-tooltip-id={`ETVSN${index}`} >
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <div style={{ height: 40, width: 40, display: "flex", justifyContent: "center", flexDirection: "column" }} >
                                <CircularProgressbar
                                  value={apiData.calculatedDrivingScore}
                                  text={`${apiData.calculatedDrivingScore}%`}
                                  styles={buildStyles({
                                    width: "10px",
                                    // textColor: "red",
                                    textSize: "30px",
                                    pathColor: apiData.calculatedDrivingScore >= 0 && apiData.calculatedDrivingScore <= 80 ? "#E21212" : apiData.calculatedDrivingScore >= 81 && apiData.calculatedDrivingScore <= 93 ? "#FFBF00" : "#19543e",
                                    trailColor: "#0095FF",
                                  })}
                                ></CircularProgressbar>
                              </div>
                              <ReactTooltip id={`ETVSN${index}`} place="top" content={apiData.calculatedDrivingScoreTool} style={{ backgroundColor: "black" }} />
                            </div>
                          </td>


                          <td style={{ paddingLeft: "20px", verticalAlign: "middle" }}><span
                            style={{ fontSize: "14px" }}
                            onMouseEnter={() => [
                              localStorage.setItem(
                                SC_USER_ID,
                                apiData.userId
                              ),
                              localStorage.setItem(
                                SC_LOGIN_USER_FIRST_NAME,
                                apiData.firstName
                              ),
                              localStorage.setItem(
                                SC_USER_USERNAME,
                                apiData.username
                              ),
                            ]}
                          >
                            <Link to={"/user-dashboard"}>
                              {apiData.firstName}
                            </Link>
                          </span></td>
                          <td  style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>{apiData.username}</td>
                          <td  style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>{apiData.totalRides}</td>

                          <td className="lightBlueColor" data-tooltip-id={`DS${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }} ><div>{`${ValueFormat.formatDecimalIfRounded(apiData.drivingScore)}`}</div>
                           
                          </td>
                          <td data-tooltip-id={`OA${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallAnticipation)}`}</div>
                            
                          </td>
                          <td data-tooltip-id={`OS${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallSelfConfidence)}`}</div>
                            
                          </td>
                          <td data-tooltip-id={`ODS${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallDrivingSkill)}`}</div>
                           
                          </td>
                          <td className="lightBlueColor" data-tooltip-id={`ODS${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{apiData.drivingBehaviourScore}</div>
                           
                          </td>
                          <td data-tooltip-id={`ODSK${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.DSTP)}`}</div>
                            
                          </td>
                          <td data-tooltip-id={`ODST${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.DSP)}`}</div>
                           
                          </td>
                         
                          <td data-tooltip-id={`MS${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.mobileScreenPointNew)}`}</div>
                            
                          </td>
                          <td data-tooltip-id={`MC${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.mobileUsePointNew)}`}</div>
                          
                          </td>
                          <td data-tooltip-id={`KD${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.totalKmSPoint)}`}</div>
                           
                          </td>
                          <td data-tooltip-id={`ABC${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.ABCPoint)}`}</div>

                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            ) : dataDisplay === false ? (
              <CardBody className="p-0">
                <div
                  
                  style={{ height: 500, overflowY: "auto" }}
                >
                  <div style={{ height: 'auto', display: 'flex', alignItems: 'center', fontSize: '14px', padding: '10px' }}>

                    </div>

                  <Table bordered style={TableStyle.table}>

                    <thead>
                    <tr>
                                                       
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Rating</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Driving<br />Score</th> 
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Name <br /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Username <br /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Total<br />Rides</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="4">Driving Risk Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="7">Driving Behavior Data</th>
                     </tr>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br /><br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br /><br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br /><br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br /><br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Risk<br /><img src={UBIDrivingScore} alt="UBIDrivingScore" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br />Anticipation <br /><img src={UBIAnticipation} alt="UBIAnticipation" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Self<br />Confidence<br /><img src={UBISelfConfidence} alt="UBISelfConfidence" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Skill<br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving<br />Behaviour<br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Style<br /><img src={UBIDrivingStyle} alt="UBIDrivingStyle" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driver <br />State<br /><img src={UBIDrivingState} alt="UBIDrivingState" style={{ marginTop: "5px", height: "20px" }} /></th>
                       
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen  <br />Usage<br /><img src={UBIMobileScreenUse} alt="UBIMobileScreenUse" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call <br />Usage<br /><img src={UBIMobileCallUse} alt="UBIMobileCallUse" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed KMS<br /> Driven<br /><img src={UBIKmsDriven} alt="UBIKmsDriven" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br />Acc/Brak/Cor<br /><img src={UBIStressStrain} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                      </tr>
                    </thead>
                    <ReactTooltip
                      id="HRAF"
                      place="top"
                      content="High Risk Alert"
                      style={{ backgroundColor: "black" }}
                    />
                    <ReactTooltip
                      id="MRAF"
                      place="top"
                      content="Medium Risk Alert"
                      style={{ backgroundColor: "black" }}
                    />
                  </Table>
                  <p
                    className="data_not_available"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.DATA_NOT_AVAILABLE}
                  </p>
                </div>
              </CardBody>
            ) : dataDisplay === "failed" ? (
              <CardBody className="p-0">
                <div
                  
                  style={{ height: 500, overflowY: "auto" }}
                >
                  <div style={{ height: 'auto', display: 'flex', alignItems: 'center', fontSize: '14px', padding: '10px' }}>

                    </div>
                  <Table bordered style={TableStyle.table}>

                    <thead>
                    <tr>
                                                       
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Rating</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Driving<br />Score</th> 
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Name <br /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Username <br /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Total<br />Rides</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="4">Driving Risk Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="7">Driving Behavior Data</th>
                     </tr>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br /><br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br /><br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br /><br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br /><br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Risk<br /><img src={UBIDrivingScore} alt="UBIDrivingScore" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br />Anticipation <br /><img src={UBIAnticipation} alt="UBIAnticipation" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Self<br />Confidence<br /><img src={UBISelfConfidence} alt="UBISelfConfidence" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Skill<br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving<br />Behaviour<br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Style<br /><img src={UBIDrivingStyle} alt="UBIDrivingStyle" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driver <br />State<br /><img src={UBIDrivingState} alt="UBIDrivingState" style={{ marginTop: "5px", height: "20px" }} /></th>
                        
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen  <br />Usage<br /><img src={UBIMobileScreenUse} alt="UBIMobileScreenUse" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call <br />Usage<br /><img src={UBIMobileCallUse} alt="UBIMobileCallUse" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed KMS<br /> Driven<br /><img src={UBIKmsDriven} alt="UBIKmsDriven" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br />Acc/Brak/Cor<br /><img src={UBIStressStrain} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                      </tr>
                    </thead>
                    <ReactTooltip
                      id="HRAFF"
                      place="top"
                      content="High Risk Alert"
                      style={{ backgroundColor: "black" }}
                    />
                    <ReactTooltip
                      id="MRAFF"
                      place="top"
                      content="Medium Risk Alert"
                      style={{ backgroundColor: "black" }}
                    />
                  </Table>
                  <p
                    className="data_not_available"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.ER_1206}
                  </p>
                </div>
              </CardBody>
            ) : (
              <CardBody className="p-0">
                <div
                  
                  style={{ height: 500, overflowY: "auto" }}
                >
                  <div style={{ height: 'auto', display: 'flex', alignItems: 'center', fontSize: '14px', padding: '10px' }}>

                    </div>
                  <Table bordered style={TableStyle.table}>

                    <thead>
                    <tr>
                                                        
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Rating</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Driving<br />Score</th> 
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Name <br /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Username <br /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Total<br />Rides</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="4">Driving Risk Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="7">Driving Behavior Data</th>
                     </tr>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br /><br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br /><br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br /><br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br /><br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Risk<br /><img src={UBIDrivingScore} alt="UBIDrivingScore" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br />Anticipation <br /><img src={UBIAnticipation} alt="UBIAnticipation" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Self<br />Confidence<br /><img src={UBISelfConfidence} alt="UBISelfConfidence" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Skill<br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving<br />Behaviour<br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Style<br /><img src={UBIDrivingStyle} alt="UBIDrivingStyle" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driver <br />State<br /><img src={UBIDrivingState} alt="UBIDrivingState" style={{ marginTop: "5px", height: "20px" }} /></th>
                        
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen  <br />Usage<br /><img src={UBIMobileScreenUse} alt="UBIMobileScreenUse" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call <br />Usage<br /><img src={UBIMobileCallUse} alt="UBIMobileCallUse" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed KMS<br /> Driven<br /><img src={UBIKmsDriven} alt="UBIKmsDriven" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br />Acc/Brak/Cor<br /><img src={UBIStressStrain} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                      </tr>
                    </thead>
                  </Table>
                  <p
                    className="fetch_data"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.FETCHING_DATA}
                  </p>
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row> */}

      <Row>
        <Col xs={12}>
        <Card>
        <CardBody style={{ padding: "0px" }}>
                <Card className="border-0">
                  <CardHeader
                    className="cardHeader"
                    style={{ paddingLeft: "12px" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <h5 className="sub_heading_new m-0">Driving Score</h5>
                        <ReactTooltip
                          id={"info"}
                          place="top"
                          // content={`
                          // Driver Behaviour Score is calculated on a scale of 100 and represents a cumulative sum of the
                          // Driving Point, Anticipation Point, Self Confidence Point, Driving Skill Point, Driving Style Point, Driver State Point, Mobile Use Point, Mobile Call Point, Overspeed KMS Driven Point, and Acc/Brak/Cor Point
                          // `}
                          multiline={true}
                          style={{
                            backgroundColor: "black",
                            height: "auto",
                            width: "40vw",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            padding: "20px",
                          }}
                        />
                      </div>

                      {/* <div className="ms-auto">
                                                <div style={{ verticalAlign: "middle", display: "flex" }}>

                                                    <div>
                                                        <div className="d-flex mt-1 align-items-center" >
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#E21212' }} />
                                                                Poor (0-80)
                                                            </div>
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#FFBF00' }} />
                                                                Fair (80-93)
                                                            </div>
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#19543E' }} />
                                                                Good (93-100)
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> */}
                    </div>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div
                      className="mb-4"
                      // style={{ height: 525, overflowY: "auto" }}
                    >
                      <div
                        style={{
                          height: "auto",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          padding: "10px",
                        }}
                      >
                        {/* {displayText.DRIVER_BEHAVIOUR_DESCRIPTION} */}
                      </div>
                      <Table bordered style={TableStyle.table}>
                        <thead>
                          {/* <tr>
                                                        
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Rating</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Driving<br />Score</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Ride <br />Name</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="4">Driving Risk Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="7">Driving Behavior Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Travel<br />Period</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Battery %</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Event</th>
                                                    </tr> */}
                          <tr>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              User<br/>Deatils
                              {/* <br/><br/><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/>Score
                              {/* <br/><br/><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>
                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/><br/><br/><img src={ride_name} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/>Risk
                              {/* <br /><img src={category_image} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>

                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Driving <br />Points<br /><img src={UBIDrivingScore} alt="UBIDrivingScore" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Risk
                              <br />
                              Data
                              {/* <img src={UBIAnticipation} alt="UBIAnticipation" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/>Self <br />Confidence <br /><img src={UBISelfConfidence} alt="UBISelfConfidence" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/>Driving <br />Skill <br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving <br />
                              Behaviour
                              {/* <img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Behaviour <br />
                              Data
                              {/* <img src={UBIDrivingStyle} alt="UBIDrivingStyle" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Crash <br />
                              Probability
                              {/* <img src={UBIDrivingState} alt="UBIDrivingState" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                           
                           
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Acc/Brak/Cor
                              <br />
                              Counts
                              {/* <img src={UBIKmsDriven} alt="UBIKmsDriven" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Over Speed
                              <br />
                              Data
                              {/* <img src={UBIStressStrain} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Mobile
                              <br />
                              Usage
                              {/* <img src={UBIMobileCallUse} alt="UBIMobileCallUse" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Red Alert <br />
                              Counts
                              {/* <img src={UBIMobileScreenUse} alt="UBIMobileScreenUse" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>

                           
                          </tr>
                        </thead>
                        {dataLength > 0 && (
                          <tbody>
                            {(summaryDataList || []).map((apiData, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                  <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={UserNameIcon}
                                        alt="icons2"
                                        style={{
                                          height: "20px",
                                          marginLeft: "-2px",
                                        }}
                                        
                                      />
                                     
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "12px",
                                        fontSize: "11px",
                                      }}
                                    >

<span
                             style={{
                              fontSize: "11px",
                              color: "#5156BE",
                              marginBottom: "0px",
                              fontWeight: 700,
                              cursor:"pointer"
                            }}
                            onMouseEnter={() => [
                              localStorage.setItem(
                                SC_USER_ID,
                                apiData.userId
                              ),
                              localStorage.setItem(
                                SC_LOGIN_USER_FIRST_NAME,
                                apiData.firstName
                              ),
                              localStorage.setItem(
                                SC_USER_USERNAME,
                                apiData.username
                              ),
                            ]}
                          >
                            <Link to={"/user-dashboard"}>
                              {apiData.firstName}
                            </Link>
                          </span>
                                 
                                   
                                 
                                  </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                  <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={userFirstNameIcon}
                                        alt="icons2"
                                        style={{
                                          height: "20px",
                                          marginLeft: "-2px",
                                        }}
                                        
                                      />
                                     
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "12px",
                                        fontSize: "11px",
                                      }}
                                    >
                                 
                                    <p
                                      style={{
                                        fontSize: "11px",
                                        color: "#5156BE",
                                        marginBottom: "0px",
                                        fontWeight: 700,
                                        cursor:"pointer"
                                      }}
                                    >{`${apiData.username}`}</p>
                                 
                                  </div>
                                  </div>

                                  
                                 <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                  <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={i4}
                                        alt="icons2"
                                        style={{
                                          height: "20px",
                                          marginLeft: "-2px",
                                        }}
                                        
                                      />
                                     
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "12px",
                                        fontSize: "11px",
                                      }}
                                    >
                                 
                                    <p
                                      style={{
                                        fontSize: "11px",
                                        color: "#5156BE",
                                        marginBottom: "0px",
                                        fontWeight: 700,
                                        cursor:"pointer"
                                      }}
                                    >{`${apiData.totalRides}`}{" "}Rides</p>
                                 
                                  </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tKiloMeter + " Kms"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tTravelTime + " Mins"}
                                    </div>
                                  </div>
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={steeringWheel}
                                        alt="icons2"
                                        style={{ height: "20px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "10px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.calculatedDrivingScore >= 0 &&
                                      apiData.calculatedDrivingScore <= 80
                                        ? "Poor"
                                        : apiData.calculatedDrivingScore >=
                                            81 &&
                                          apiData.calculatedDrivingScore <= 93
                                        ? "Fair"
                                        : "Good"}
                                    </div>
                                  </div> */}
                                </td>
                                {/* <td style={{ textAlign: "center", verticalAlign: "middle" }}>{apiData.calculatedDrivingScore >= 0 && apiData.calculatedDrivingScore <= 80 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Poor</p><button className="btn" style={{ backgroundColor: '#E21212' }} /></div> :
                                                                    apiData.calculatedDrivingScore >= 81 && apiData.calculatedDrivingScore <= 93 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Fair</p><button className="btn" style={{ backgroundColor: '#FFBF00' }} /></div> :
                                                                        <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Good</p><button className="btn" style={{ backgroundColor: '#19543E' }} /></div>}
                                                                </td> */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                  data-tooltip-id={`ETVN${index}`}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.calculatedDrivingScore}
                                        text={`${apiData.calculatedDrivingScore}%`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:
                                            apiData.calculatedDrivingScore >=
                                              0 &&
                                            apiData.calculatedDrivingScore <= 80
                                              ? "#E21212"
                                              : apiData.calculatedDrivingScore >=
                                                  81 &&
                                                apiData.calculatedDrivingScore <=
                                                  93
                                              ? "#FFBF00"
                                              : "#19543e",
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                    <ReactTooltip
                                      id={`ETVN${index}`}
                                      place="top"
                                      content={apiData.totalEfficiencyTVNew}
                                      style={{ backgroundColor: "black" }}
                                    />
                                    
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                     justifyContent:"center",
                                     marginTop:"20px"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={steeringWheel}
                                        alt="icons2"
                                        style={{ height: "20px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",                                        
                                        fontSize: "11px",
                                        marginLeft:"5px"
                                        
                                      }}
                                    >
                                       {apiData.calculatedDrivingScore >= 0 &&
                                      apiData.calculatedDrivingScore <= 50
                                        ? "Bad"
                                        : apiData.calculatedDrivingScore >
                                            50 &&
                                          apiData.calculatedDrivingScore <= 80
                                        ? "Dangerous"
                                        : apiData.calculatedDrivingScore > 80 && apiData.calculatedDrivingScore <= 93 ? "Quiet Safe" : apiData.calculatedDrivingScore > 93 && apiData.calculatedDrivingScore <= 100 ? "Good" : ""}
                                    </div>
                                  </div>
                                </td>
                                {/* <td className="lightBlueColor text-nowrap" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                    <Link
                                                                        to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(apiData.userId))}&divisionId=${encodeURIComponent(Converter.encrypt(apiData.divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}&rideCategory=${encodeURIComponent(Converter.encrypt(apiData.rideCategory))}`}>
                                                                        <span
                                                                            data-tooltip-id={`rideTooltip_${index}`}
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            {apiData.rideName}
                                                                        </span>
                                                                    </Link>
                                                                    <ReactTooltip
                                                                        id={`rideTooltip_${index}`}
                                                                        place="top"
                                                                        effect="solid"
                                                                        backgroundColor="black"
                                                                        content={apiData.StatusTemp}
                                                                    />
                                                                </td> */}
                                {/* <td className="lightBlueColor text-nowrap" data-tooltip-id={`categoryTooltip_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.categoryTooltip}`} */}
                                {/* <ReactTooltip
                                                                        id={`categoryTooltip_${index}`}
                                                                        place="top"
                                                                        effect="solid"
                                                                        backgroundColor="black"
                                                                        content={apiData.categoryTooltip}
                                                                    /> */}
                                {/* </td> */}
                                <td
                                  className="text-nowrap"
                                  data-tooltip-id={`categoryTooltip_${index}`}
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${ValueFormat.formatDecimalIfRounded(
                                      apiData.drivingScore
                                    )}`,
                                  }} // This will render the <br/> as an actual line break
                                />
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{"-"}</div></td> */}
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{"-"}</div></td> */}
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIAnticipationNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Anticipation
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.overallAnticipation
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBISelfConfidence}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "7px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Self Confidence
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallSelfConfidence
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingSkill}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Skill
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallDrivingSkill
                                      )}
                                    </div>
                                  </div>
                                </td>
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallSelfConfidence)}`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallDrivingSkill)} `}</div></td> */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                    fontSize: "11px",
                                  }}
                                >
                                  <div style={{fontSize: "13px"}}>{`${apiData.drivingBehaviourScore}`}</div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingStyle}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Style
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.DSTP
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingState}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving State
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.DSP
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIMobileCallUse}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "9px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Mobile Usage
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.mobileUsage}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Over Speed
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.totalKmSPoint
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIStressStrain}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          ABC
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.ABCPoint
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value="-"
                                        text="-"
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          // pathColor:apiData.cppLevelColor,                                          
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                    
                                   
                                  </div>
                                  <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                      <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"20px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L0 is Safe</p>

                                    </div>
                                      
                                    </div>
                                    <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"-5px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L6 is Unsafe</p>

                                    </div>
                                    </div>
                                  {/* <div>
                                    Level{" - "}
                                    {apiData.cppLevel}
                                  </div> */}
                                  {/* <div style={{ marginTop: "15px" }}>
                                    Point(s){" - "}
                                    {apiData.crashProbabilityScore || 0}
                                  </div> */}
                                </td>
                                
                               
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={AccelerationIcon}
                                          alt="totalAccelerationCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Acc
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalAccelerationCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={BreakingIcon}
                                          alt="totalBrakingCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Brak
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalBrakingCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={CorneringIcon}
                                          alt="totalCorneringCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Cor
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalCorneringCount}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                   <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      
                                      {apiData.totalOverSpeedCount}{" "}{"Count"}
                                    </div>
                                  </div>           

                                
                                  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalOverSpeedDuration}{" "}{"Mins"}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                   <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={ScreenCountIcon}
                                        alt="icons2"
                                        style={{ height: "24px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnCount}{" "}{"Count"}
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnDuration}{" "}{"Mins"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CallCountIconNewOne}
                                        alt="icons2"
                                        style={{ height: "26px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Count"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Mins"}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                 <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalHighRiskCount}{" "}{"Count"}
                                    </div>
                                  </div>           

                                  
                                  
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {"-"}
                                    </div>
                                  </div> */}
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {"-"}
                                    </div>
                                  </div> */}
                                </td>
                                
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>

                      {dataLength === 0 && (
                        <p
                          className="data_not_available"
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card>

               
              </CardBody>
        </Card>
        </Col>
      </Row>


      {/* <Row>
        <Col xs={12}>
          <Card>
            <CardHeader className="cardHeader">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="sub_heading m-0">Dashboard for Violation</h5>
                <div className="ms-auto">
                  <div style={{ verticalAlign: "middle", display: "flex" }}>
                    <div>
                      <div className="d-flex mt-1 align-items-center" >
                        <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                          <button className="btn me-1" style={{ backgroundColor: '#E21212' }} />
                          Poor (0-80)
                        </div>
                        <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                          <button className="btn me-1" style={{ backgroundColor: '#FFBF00' }} />
                          Fair (80-93)
                        </div>
                        <div className="me-1 d-flex align-items-center" style={{ fontSize: "14px" }}>
                          <button className="btn me-1" style={{ backgroundColor: '#19543E' }} />
                          Good (93-100)
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </CardHeader>
            {dataDisplay === true ? (
              <CardBody className="p-0">
                <div style={{ height: 420, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Rating
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Score<br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Name <br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Username <br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total <br />Rides <br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Distance <br /> (kms) <br /><img src={tdistance} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Duration <br /> (Mins) <br /><img src={tduration} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Crash <br /> (Count) <br /><img src={crash} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="HRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >High Risk<br /> (Count) <br /><img src={i5} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="MRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Medium Risk<br /> (Count)<br /><img src={i6} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TOC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed<br /> (Mins | Count)<br /><img src={i7} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMSC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen<br />(Mins | Count) <br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call<br />(Mins | Count) <br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Acceleration<br />(Count) <br /><img src={i10} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TBC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Braking <br />(Count) <br /><img src={i11} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Cornering<br />(Count) <br /><img src={i12} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                      </tr>
                    </thead>
                    <ReactTooltip id="HRAC" place="top" content="Total High Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="MRAC" place="top" content="Total Medium Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TOC" place="top" content="Total Overspeed Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMCC" place="top" content="Total Mobile Call Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMSC" place="top" content="Total Mobile Screen Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TAC" place="top" content="Total Acceleration Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TBC" place="top" content="Total Braking Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TCC" place="top" content="Total Cornering Count" style={{ backgroundColor: "black" }} />
                    <tbody>
                      {(summaryDataList || []).map((apiData, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center", verticalAlign: "middle" }}>{apiData.calculatedDrivingScore >= 0 && apiData.calculatedDrivingScore <= 80 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Poor</p><button className="btn" style={{ backgroundColor: '#E21212' }} /></div> :
                           apiData.calculatedDrivingScore >= 81 && apiData.calculatedDrivingScore <= 93 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Fair</p><button className="btn" style={{ backgroundColor: '#FFBF00' }} /></div> :
                              <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Good</p><button className="btn" style={{ backgroundColor: '#19543E' }} /></div>}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }} data-tooltip-id={`ETV${index}`} >
                            <div style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ height: 40, width: 40, display: "flex", justifyContent: "center", flexDirection: "column" }} >
                                <CircularProgressbar
                                  value={apiData.calculatedDrivingScore}
                                  text={`${apiData.calculatedDrivingScore}%`}
                                  styles={buildStyles({
                                    width: "10px",
                                    // textColor: "red",
                                    textSize: "30px",
                                    pathColor: apiData.calculatedDrivingScore >= 0 && apiData.calculatedDrivingScore <= 80 ? "#E21212" : apiData.calculatedDrivingScore >= 81 && apiData.calculatedDrivingScore <= 93 ? "#FFBF00" : "#19543e",
                                    trailColor: "#0095FF",
                                  })}
                                ></CircularProgressbar>
                              </div>
                              <ReactTooltip id={`ETV${index}`} place="top" content={apiData.calculatedDrivingScoreTool} style={{ backgroundColor: "black" }} />
                            </div>
                          </td>
                          <td className="lightBlueColor" style={{ paddingLeft: "20px", verticalAlign: "middle" }}><span
                            style={{ fontSize: "14px" }}
                            onMouseEnter={() => [
                              localStorage.setItem(
                                SC_USER_ID,
                                apiData.userId
                              ),
                              localStorage.setItem(
                                SC_LOGIN_USER_FIRST_NAME,
                                apiData.firstName
                              ),
                              localStorage.setItem(
                                SC_USER_USERNAME,
                                apiData.username
                              ),
                            ]}
                          >
                            <Link to={"/user-dashboard"}>{apiData.firstName}</Link></span>
                          </td>
                          <td className="lightBlueColor" style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>{apiData.username}</td>
                          <td className="lightBlueColor" style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>{apiData.totalRides}</td>
                          <td className="lightBlueColor" data-tooltip-id={`tkilometer_${index}`} style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>
                            {apiData.tKiloMeter}
                            <ReactTooltip id={`tkilometer_${index}`} place="top" effect="solid" backgroundColor="black" content={apiData.tKiloMeterTV} />
                          </td>
                          <td className="lightBlueColor" data-tooltip-id={`tTravelTime_${index}`} style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>
                            {apiData.tTravelTime}
                            <ReactTooltip id={`tTravelTime_${index}`} place="top" effect="solid" backgroundColor="black" content={apiData.tTravelTimeTV} />
                          </td>
                          <td className="" style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>{apiData.crashCount}</td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalHighRiskCount}`}</td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalMediumRiskCount}`}</td>
                          <td data-tooltip-id={`overSpeedDurationCount_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>
                            {`${apiData.totalOverSpeedDuration} | ${apiData.totalOverSpeedCount}`}
                           
                          </td>
                          <td data-tooltip-id={`mobileScreenDurationCount_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>
                            {`${apiData.totalMobileScreenScreenOnDuration} | ${apiData.totalMobileScreenScreenOnCount}`}
                            
                          </td>
                          <td data-tooltip-id={`mobileCallDurationCount_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>
                            {`${apiData.totalMobileUseInAcceptedDuration} | ${apiData.totalMobileUseInAcceptedCount}`}
                           
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalAccelerationCount}`}</td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalBrakingCount}`}</td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalCorneringCount}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            ) : dataDisplay === false ? (
              <CardBody className="p-0">
                <div style={{ height: 420, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Rating
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving Behaviour <br />Score<br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Name <br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Username <br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total <br />Rides <br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Distance <br /> (kms) <br /><img src={tdistance} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Duration <br /> (Mins) <br /><img src={tduration} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Crash <br /> (Count) <br /><img src={crash} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="HRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >High Risk<br /> (Count) <br /><img src={i5} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="MRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Medium Risk<br /> (Count)<br /><img src={i6} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TOC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed<br /> (Mins | Count)<br /><img src={i7} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMSC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen<br />(Mins | Count) <br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call<br />(Mins | Count) <br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Acceleration<br />(Count) <br /><img src={i10} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TBC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Braking <br />(Count) <br /><img src={i11} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Cornering<br />(Count) <br /><img src={i12} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                      </tr>
                    </thead>
                    <ReactTooltip id="HRAC" place="top" content="Total High Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="MRAC" place="top" content="Total Medium Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TOC" place="top" content="Total Overspeed Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMCC" place="top" content="Total Mobile Call Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMSC" place="top" content="Total Mobile Screen Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TAC" place="top" content="Total Acceleration Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TBC" place="top" content="Total Braking Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TCC" place="top" content="Total Cornering Count" style={{ backgroundColor: "black" }} />
                  </Table>
                  <p
                    className="data_not_available"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.DATA_NOT_AVAILABLE}
                  </p>
                </div>
              </CardBody>
            ) : dataDisplay === "failed" ? (
              <CardBody className="p-0">
                <div style={{ height: 420, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Rating
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving Behaviour <br />Score<br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Name <br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Username <br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total <br />Rides <br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Distance <br /> (kms) <br /><img src={tdistance} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Duration <br /> (Mins) <br /><img src={tduration} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Crash <br /> (Count) <br /><img src={crash} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="HRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >High Risk<br /> (Count) <br /><img src={i5} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="MRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Medium Risk<br /> (Count)<br /><img src={i6} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TOC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed<br /> (Mins | Count)<br /><img src={i7} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMSC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen<br />(Mins | Count) <br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call<br />(Mins | Count) <br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Acceleration<br />(Count) <br /><img src={i10} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TBC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Braking <br />(Count) <br /><img src={i11} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Cornering<br />(Count) <br /><img src={i12} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                      </tr>
                    </thead>
                    <ReactTooltip id="HRAC" place="top" content="Total High Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="MRAC" place="top" content="Total Medium Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TOC" place="top" content="Total Overspeed Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMCC" place="top" content="Total Mobile Call Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMSC" place="top" content="Total Mobile Screen Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TAC" place="top" content="Total Acceleration Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TBC" place="top" content="Total Braking Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TCC" place="top" content="Total Cornering Count" style={{ backgroundColor: "black" }} />
                  </Table>
                  <p
                    className="data_not_available"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.ER_1206}
                  </p>
                </div>
              </CardBody>
            ) : (
              <CardBody className="p-0">
                <div style={{ height: 420, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Rating
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving Behaviour <br />Score<br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Name <br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Username <br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total <br />Rides <br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Distance <br /> (kms) <br /><img src={tdistance} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Duration <br /> (Mins) <br /><img src={tduration} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Crash <br /> (Count) <br /><img src={crash} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="HRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >High Risk<br /> (Count) <br /><img src={i5} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="MRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Medium Risk<br /> (Count)<br /><img src={i6} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TOC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed<br /> (Mins | Count)<br /><img src={i7} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMSC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen<br />(Mins | Count) <br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call<br />(Mins | Count) <br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Acceleration<br />(Count) <br /><img src={i10} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TBC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Braking <br />(Count) <br /><img src={i11} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Cornering<br />(Count) <br /><img src={i12} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                      </tr>
                    </thead>
                    <ReactTooltip id="HRAC" place="top" content="Total High Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="MRAC" place="top" content="Total Medium Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TOC" place="top" content="Total Overspeed Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMCC" place="top" content="Total Mobile Call Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMSC" place="top" content="Total Mobile Screen Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TAC" place="top" content="Total Acceleration Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TBC" place="top" content="Total Braking Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TCC" place="top" content="Total Cornering Count" style={{ backgroundColor: "black" }} />
                  </Table>
                  <p
                    className="fetch_data"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.FETCHING_DATA}
                  </p>
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row> */}
    </div>
  );
}
